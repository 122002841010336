import React, { useState } from 'react'
import './Modal.css'
import { Modal, Button } from 'react-bootstrap'
function ModalContent({content,button_text}) {
    
        const [isShow, invokeModal] = useState(false)
        const initModal = () => {
            
          return invokeModal(!false)
        }
        const closeModal=()=>{
          // window.location.reload ()
          return invokeModal(false)
        }
        
        return (
          <div >
            <div className="init text-white" >
            <Button variant="" className="create-btn text-white"onClick={initModal}>
              {button_text}
            </Button>
            </div>
            <Modal show={isShow}>
              {/* <Modal.Header closeButton onClick={initModal}>
                <Modal.Title>React Modal Popover Example</Modal.Title>
              </Modal.Header> */}
              <Modal.Body className="modalbox ">
               
                <div className="">
{content}
                </div>
                {/* <Button variant="danger" onClick={closeModal}>
                  Close
                </Button> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant="danger" onClick={closeModal}>
                  Close
                </Button>
                
              </Modal.Footer> */}
            </Modal>
          </div>
          
        )
      }
      export default ModalContent
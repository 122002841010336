import React, { useEffect, useState } from 'react'
import './Videos.css'

function Videos() {
  const [videos,setvideos]=useState('')
  const [videosSorted,setvideosSorted]=useState('')
  useEffect(() => {
    fetch(process.env.REACT_APP_VIDEOS_FETCH)
      .then((response) => response.json())
      .then((data) => {
        
        if (data) {
          setvideos(data);
        } else {
          
        }
      });
  }, []);

  useEffect(()=>{
    function GetSortOrder(prop) {    
      return function(a, b) {    
          if (a[prop] > b[prop]) {    
              return 1;    
          } else if (a[prop] < b[prop]) {    
              return -1;    
          }    
          return 0;    
      }    
  }    
      
  const list=videos && videos.sort(GetSortOrder("order"));  
  setvideosSorted(list && list.reverse())
  },[videos])
 
  return (
    <div className='container'>
        <div className="videos">
            <div className='videos__title'>Recommended Videos</div>
            <div className='videos__content row'>
              {videosSorted && videosSorted.map((video)=>{
                return(
                  <div className="single__video col-12 col-sm-6 col-lg-4">
                    <div dangerouslySetInnerHTML={{__html: video.link}}></div>
                  <div className="single__video__title text-center">
                  {video.title}
                  </div>
                  </div>
                )
              })}

  
       
      </div>
      
      
      </div>
    </div>
  )
}

export default Videos
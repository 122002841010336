import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./User.css";
import { auth, db } from "../../firebase";

import Project from "../GroupProjects/Project";
import {
  arrayRemove,
  doc,
  collectionGroup,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useStateValue } from "../StateProvider/StateProvider";
import { onAuthStateChanged } from "firebase/auth";
import SignInFirstModal from "../Sign/SIgnInFirstModal/SignInFirstModal";
function User() {
  const [{ basket, user }, dispatch] = useStateValue();

  const [Projects, setProjects] = useState([]);
  const [usertitles, setusertitles] = useState([]);
  const [titlearray, settitlearray] = useState([]);
  const [userList, setuserList] = useState([]);
  const [userProject, setuserProject] = useState([]);
  const [currentuser, setcurrentuser] = useState("");

  // ---for hosting---
  const [zoomlink1, setzoomlink1] = useState("");
  const [zoomlink2, setzoomlink2] = useState("");
  const [projectid, setprojectid] = useState("");

  // ------------------------------------------------------------
  // -------------fetch title list of user by email to array--------------
  const array = [];
  useEffect(() => {
    const myReviews = firebase.firestore().collectionGroup("participants");

    myReviews.get()

      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setProjects(
            querySnapshot.docs.map((doc) => ({
              data: doc.data(),
            }))
          );
        });
      });
  }, []);

  // --------------------------------------------
  // ----------setting user email----------------
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setcurrentuser(email);
      } else {
        setcurrentuser("null");
      }
    });
  });
  // ----------setting user email----------------
  // --------------------------------------------

  // -------------------------------------------------------------------
  // -------------fetch title list of user by email to array--------------------

  useEffect(() => {
    const titlelist = [];
    Projects?.map((item) => {
      if (item.data.email === currentuser) {
        titlelist.push(item.data.title);
      }
      setusertitles(titlelist);
    });
  }, [Projects]);

  // -------------fetch title list of user by email to array--------------------
  // -------------------------------------------------------------------

  // ------------------------------------------------------------------
  // --------------------fetching all projects list--------------------
  useEffect(() => {
    db.collection("hosting_form")
      .orderBy("st_date")
      .onSnapshot((snapshot) =>
        setuserProject(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  }, []);

  // --------------------fetching all projects list--------------------
  // ------------------------------------------------------------------

  const projectidset = (e) => {
    // e.preventDefault();
    setprojectid(e.target.value);
    // alert(projectid)
  };
  const zoomlinkset = (e) => {
    // e.preventDefault();
    setzoomlink2(e.target.value);

    updatezoom();
  };
  const updatezoom = () => {
    db.collection("hosting_form")
      .doc(projectid)
      .update({
        zoom_link: zoomlink2,
      })
      .then(() => {
        alert("meeting session started");
        // updateProject();
        window.location.reload();
      });
  };
  console.log(zoomlink2);
  const zoomremove = () => {
    db.collection("hosting_form")
      .doc(projectid)
      .update({
        zoom_link: "",
      })
      .then(() => {
        alert("curret session stopped");
        // updateProject();
        window.location.reload();
      });
  };

  
const filterProjects=(e)=>{
  let Projects=document.querySelector('.Projects')
  let Hostings=document.querySelector('.Hostings')
  let usefull=document.querySelector('.usefull')
   Projects && Projects.classList.remove('d-none')
  Hostings && Hostings.classList.add('d-none')
  usefull && usefull.classList.add('d-none')
  window.scrollTo(0, 0);

}
const filterHostings=(e)=>{
  let Projects=document.querySelector('.Projects')
  let Hostings=document.querySelector('.Hostings')
  let usefull=document.querySelector('.usefull')
  Projects && Projects.classList.add('d-none')
  Hostings && Hostings.classList.remove('d-none')
  usefull && usefull.classList.add('d-none')
  window.scrollTo(0, 0);
}
const filterall=(e)=>{
  let Projects=document.querySelector('.Projects')
  let Hostings=document.querySelector('.Hostings')
  let usefull=document.querySelector('.usefull')
  Projects && Projects.classList.remove('d-none')
  Hostings && Hostings.classList.remove('d-none')
  usefull && usefull.classList.remove('d-none')
  window.scrollTo(0, 0);
}
console.log(userProject)
  return (
    <div className="user  ">
      {/* <div className="user__head d-flex justify-content-center"> */}
      
         <div className="user__header d-flex px-2 px-md-0 justify-content-center flex-md-column col-md-2 ">
        
         <button className='adminmenu__header__navs usefull_btn' onClick={e=>filterall()}>all</button>
        <button className='adminmenu__header__navs Projects_btn' onClick={e=>filterProjects()}>Projects</button>
      <button className='adminmenu__header__navs Hostings_btn' onClick={e=>filterHostings()}>Hostings</button>
      
        
        </div>
        
      
   
{/* </div> */}
      <div className="user__content row g-0">
      <div className="user__head d-flex d-md-block justify-content-center flex-md-column col-md-2">

         
      </div>
      <div className="user__bottom col-md-10 px-md-5">
        <div className="user__projects Projects container" >
          <div>
            <div className="user__projects__head ">
              YOUR JOINED PROJECTS LIST
            </div>
          </div>
          
          {
          
          userProject && userProject.map((userProject) => {
              const title = userProject.data.Project_title;
           
              for (let index = 0; index < usertitles.length; index++) {
                if (title === usertitles[index]) {
                  return (
                    <div
                      key={userProject.id}
                      className="user__projects__content"
                    >
                      <table className="container ">
                        <thead >
                          <th colSpan={2} className="text-center fw-bold fs-1 text-uppercase">{userProject.data.Project_title}</th>
                        </thead>
                        <tbody>
                          <td>Project Title</td>

                          <td>{userProject.data.Project_title}</td>
                        </tbody>
                        {userProject.data.Project_link && (
                          <tbody>
                            <td>Project Link</td>
                            <td>{userProject.data.Project_link}</td>
                          </tbody>
                        )}
                        {userProject.data.github_link && (
                          <tbody>
                            <td>Github Link</td>
                            <td>{userProject.data.github_link}</td>
                          </tbody>
                        )}
                        {userProject.data.st_date && (
                          <tbody>
                            <td>Starting Date</td>
                            <td>{userProject.data.st_date}</td>
                          </tbody>
                        )}
                        {userProject.data.day1 && (
                          <tbody>
                            <td>Weekly Schedule Day 1</td>
                            <td>
                              {userProject.data.day1} at{" "}
                              {userProject.data.time1} EST (washington DC time)
                            </td>
                          </tbody>
                        )}
                        {userProject.data.day2 && (
                          <tbody>
                            <td>Weekly Schedule Day 2</td>
                            <td>
                              {userProject.data.day2} at{" "}
                              {userProject.data.time2} EST (washington DC time)
                            </td>
                          </tbody>
                        )}
                        {userProject.data.project_delay && (
                          <tbody>
                            <td>Estimated Project Delay</td>
                            <td>{userProject.data.project_delay} Weeks</td>
                          </tbody>
                        )}
                        {userProject.data.Project_description && (
                          <tbody>
                            <td>Project Description</td>
                            <td>{userProject.data.Project_description}</td>
                          </tbody>
                        )}
                        {userProject.data.Project_des && (
                          <tbody>
                            <td>Design Files</td>
                            <td>{userProject.data.Project_des}</td>
                          </tbody>
                        )}
                        {userProject.data.files_associated && (
                          <tbody>
                            <td>Files associated to the project</td>
                            <td>{userProject.data.files_associated}</td>
                          </tbody>
                        )}
                        {userProject.data.fname && (
                          <tbody>
                            <td>Host </td>
                            <td>
                              {userProject.data.fname} {userProject.data.lname}
                            </td>
                          </tbody>
                        )}
                        {userProject.data.zoom_link && (
                          <tbody className="">
                            <td className="zoom__body">
                              <span>Meeting link</span>
                            </td>
                            <td>
                              <a
                                href={userProject.data.zoom_link}
                                target="_blank"
                              >
                                {" "}
                                <span className="zoom__body">
                                  This meeting is Started
                                </span>{" "}
                                <span className="zoom__btn">
                                  Click to Join !!
                                </span>
                              </a>
                            </td>
                          </tbody>
                        )}
                      </table>
                    </div>
                  )
                }
              }
            })}
        </div>
        <div className="user__hosting Hostings" >
          <div className="user__hosting__head">YOUR HOSTINGS</div>
         
          {userProject &&
            userProject.map((userProject) => {
              if (userProject.data.email === currentuser) {
                return (
                  <div key={userProject.id} className="user__hosting__content">
                    <table className="container">
                      <thead>
                        <th colSpan={2} className="text-center text-uppercase fs-1">{userProject.data.Project_title}</th>
                      </thead>
                      <tbody>
                        <td>Project Title</td>

                        <td>{userProject.data.Project_title}</td>
                      </tbody>
                      {userProject.data.Project_link && (
                        <tbody>
                          <td>Project Link</td>
                          <td>{userProject.data.Project_link}</td>
                        </tbody>
                      )}
                      {userProject.data.github_link && (
                        <tbody>
                          <td>Github Link</td>
                          <td>{userProject.data.github_link}</td>
                        </tbody>
                      )}
                      {userProject.data.st_date && (
                        <tbody>
                          <td>Starting Date</td>
                          <td>{userProject.data.st_date}</td>
                        </tbody>
                      )}
                      {userProject.data.day1 && (
                        <tbody>
                          <td>Weekly Schedule Day 1</td>
                          <td>
                            {userProject.data.day1} at {userProject.data.time1}{" "}
                            EST (washington DC time)
                          </td>
                        </tbody>
                      )}
                      {userProject.data.day2 && (
                        <tbody>
                          <td>Weekly Schedule Day 2</td>
                          <td>
                            {userProject.data.day2} at {userProject.data.time2}{" "}
                            EST (washington DC time)
                          </td>
                        </tbody>
                      )}
                      {userProject.data.project_delay && (
                        <tbody>
                          <td>Estimated Project Delay</td>
                          <td>{userProject.data.project_delay} Weeks</td>
                        </tbody>
                      )}
                      {userProject.data.Project_description && (
                        <tbody>
                          <td>Project Description</td>
                          <td>{userProject.data.Project_description}</td>
                        </tbody>
                      )}
                      {userProject.data.Project_des && (
                        <tbody>
                          <td>Design Files</td>
                          <td>{userProject.data.Project_des}</td>
                        </tbody>
                      )}
                      {userProject.data.files_associated && (
                        <tbody>
                          <td>Files associated to the project</td>
                          <td>{userProject.data.files_associated}</td>
                        </tbody>
                      )}
                      {userProject.data.fname && (
                        <tbody>
                          <td>Host </td>
                          <td>
                            {userProject.data.fname} {userProject.data.lname}
                          </td>
                        </tbody>
                      )}
                      <tbody>
                        <td>Zoom Link </td>
                        <td className="zoom__link px-2">
                          <input
                            placeholder="paste the zoom link you created"
                            className="w-100"
                            defaultValue={userProject.data.zoom_link}
                            onChange={(e) => setzoomlink2(e.target.value)}
                          ></input>
                          <div className="d-md-flex pt-2">
                          <button
                            value={userProject.id}
                            onMouseOver={projectidset}
                            onClick={zoomlinkset}
                            className="zoom__btn px-3"
                          >
                            OPEN MEETING{" "}
                          </button>
                          <button
                          value={userProject.id}
                            onMouseOver={projectidset}
                            onClick={zoomremove}
                            className="zoom__btn px-3"
                          >
                            CLOSE MEETING
                          </button>
                          </div>
                        </td>
                      </tbody>
                    </table>
                  </div>
                );
              }
            })}
        </div>

        <div className="user__tutor"></div>
      </div>
      </div>
      {/* <SignInFirstModal/> */}
    </div>
  );
}

export default User;

import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import "./CreateMeeting.css";
import { Table } from "react-bootstrap";
import { auth } from "../../firebase";
import ModalContent from "../Modal/Modal";
import { useStateValue } from "../StateProvider/StateProvider";

function CreateMeeting() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [todayPlan, settodayPlan] = useState("hhhh");
  const [meetingdate, setmeetingdate] = useState("");
  const [start_time, setstart_time] = useState("");
  const [meeting_link, setmeeting_link] = useState("");
  const [currentuser, setcurrentuser] = useState("");
  const [student_email, setstudent_email] = useState("");
  const [students, setstudents] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setcurrentuser(email);
      } else {
        // alert("sign in first");
      }
    });
  });
  // console.log(currentuser)

  useEffect(() => {
    fetch(process.env.REACT_APP_STUDENTS_FETCH)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setstudents(data);
          // console.log(data);
        } else {
          // console.log(err)
        }
      });
  }, []);
  const st_time = (e) => {
    e.preventDefault();
    var today = new Date();
    setstart_time(today.getTime());
    // dConvert(start_time)
    // var time = today.getHours() + ":" + today.getMinutes()
    // setstart_time(today.getTime())
    // setmeetingdate(time)
    var dateNow = new Date();
    let previousMonth = dateNow.toLocaleString("en-US", {
      month: "long",
    });
    console.log(previousMonth);
    var currentdate =
      dateNow.toLocaleString("en-US", {
        month: "long",
      }) +
      "-" +
      dateNow.getDate() +
      " - " +
      dateNow.getFullYear();
    setmeetingdate(currentdate);
  };
  const dConvert = (start_time) => {
    const tonum = Number(start_time);
    const date = new Date(tonum);

    var currentdate =
      date.toLocaleString("en-US", {
        month: "long",
      }) +
      "-" +
      date.getDate() +
      " - " +
      date.getFullYear();
    // setmeetingdate(currentdate)
    if (start_time === true) {
      return "currentdate";
    } else {
      return currentdate;
    }
  };

  const tConvert = (start_time) => {
    const tonum = Number(start_time);
    const date = new Date(tonum);
    var time = date.getHours() + ":" + date.getMinutes();
    time = time
      .toString()
      .match(/^(?:(?:([01]?\d|2[0-3])(:))?([0-5]?\d))$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  const close = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  function formSubmit() {
    if (start_time === "") {
      alert("Please click Start time");
    } else if (student_email === "") {
      alert("Please Enter student email");
    } else if (meeting_link === "") {
      alert("Please Enter meeting link");
    } else {
      document.getElementById("foo").submit();
    }
  }

  return (
    <div className="createMeeting">
      <ModalContent
        content={
          <form
            id="foo"
            action={process.env.REACT_APP_ADD_REPORT}
            method="post"
          >
            <Table className="container">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>
                    <input
                      className="d-none"
                      id="meetingdate"
                      name="meetingdate"
                      value={meetingdate}
                      required
                    ></input>
                    {start_time ? dConvert(start_time) : ""}
                  </th>
                </tr>
              </thead>
              <tbody className="createtbody">
                <tr>
                  <td>User Email</td>
                  <td colspan="2">
                    <input
                      id="user_email"
                      name="user_email"
                      value={currentuser}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td>Student name</td>
                  <td colspan="2">
                    <select onChange={(e) => setstudent_email(e.target.value)}>
                      <option value="" selected disabled>select student</option>
                      {students &&
                        students.map((student) => {
                          
                          if (currentuser === student.tutoremail) {
                            
                            return (
                              
                              <option value={student.studentemail} key={student.student_id}>
                                {student.fname} {student.lname}
                              </option>
                            );
                          }
                        })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Student Email</td>
                  <td colspan="2">
                    {" "}
                    <input
                      id="student_email"
                      name="student_email"
                      value={student_email}
                      required
                      onChange={(e) => setstudent_email(e.target.value)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td>meeting Link</td>
                  <td colspan="2">
                    <input
                      type="text"
                      id="meeting_link"
                      name="meeting_link"
                      value={meeting_link}
                      onChange={(e) => setmeeting_link(e.target.value)}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex">
                    <button className="starter" onClick={st_time} required>
                      Start Timer
                    </button>
                  </td>
                  <td>
                    Meeting Started at {tConvert(start_time)}
                    <input
                      className="d-none"
                      value={start_time}
                      name="start_time"
                      id="start_time"
                      required
                    ></input>
                    <input
                      className="d-none"
                      value="pending"
                      name="report_status"
                      id="report_status"
                      
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td className="cancel">
                    {" "}
                    <button value="cancel" onClick={close}>
                      {" "}
                      Cancel
                    </button>
                  </td>

                  <td className="submit">
                    {" "}
                    <input
                    type="button"
                      id="submittt"
                      onClick={formSubmit}
                      value="Submit"
                    ></input>
                  </td>
                </tr>
              </tbody>
            </Table>
          </form>
        }
        button_text={"Create New Meeting"}
      />
    </div>
  );
}

export default CreateMeeting;

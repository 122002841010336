import React, { useEffect, useState } from 'react'
import SignInFirstModal from '../../../Sign/SIgnInFirstModal/SignInFirstModal';
import { useStateValue } from '../../../StateProvider/StateProvider';
import ModalQuestion from './ModalQuestion';
import './QandA.css'
import QandASingle from './QandASingle';

function QandA() {
    const [{ basket, user }, dispatch] = useStateValue();
    const [questions,setquestions]=useState("")
  
    useEffect(() => {
        fetch("https://php.andmtaskills.com/index.php?action=get_QandA")
          .then((response) => response.json())
          .then((data) => {
            
            if (data) {
              setquestions(data);
            } else {
           
            }
          });
      }, []);
      
  
    function myFunction() {
        
        var input, filter, i, txtValue;
        input = document.getElementById("myInputq");
    
        let list = document.querySelector(".questions__list");
        let single = list && list.getElementsByClassName("qandasingle");
    
        filter = input.value.toUpperCase();
      
        for (i = 0; i < single.length; i++) {
          txtValue = single[i].textContent;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            single[i].style.display = "";
          } else {
            single[i].style.display = "none";
          }
        }
      }
  return (
    <div className='qanda container'>
    <div className="d-flex justify-content-end">
      <input
        className="usefull_search mt-3"
        type="text"
        id="myInputq"
        onChange={(e) => myFunction()}
        placeholder="Search . . ."
        title="Type in a name"
      ></input></div>
{!user?<SignInFirstModal
        button_text={<button className="bg-success text-white rounded px-3 py-2">Add New Answer</button>}/>:<ModalQuestion/>}
      
    

<div className='questions__list'>
{questions && questions.map((question)=>{
    return(<>
    <QandASingle
    question={question.question}
    answer={question.answer}
    link={question.link}
    />
 
    </>)
})}
</div>
    
    
    
    </div>
  )
}

export default QandA
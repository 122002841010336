import React from 'react'
import './Templates.css'
function SingleTemplate({image,title,description,price,preview,download}) {
    const seemore = (e)=>{
        e.preventDefault()
        let single__template__disc=document.querySelector('.single__template__disc')
        let button=document.querySelector('.single__answer__see__more')
     
        e.target.parentElement.children[0].classList.toggle('template__show');
        e.target.parentElement.children[1].classList.toggle('d-none')
        e.target.parentElement.children[2].classList.toggle('d-none')
      }

  return (
    <div>
        <div className=' single__template container my-3'>
            <div className='row py-3 '>
            <div className='single__template__thumb  col-md-4 col-lg-3'><img className='w-100' src={image}></img></div>
            <div className='col-md-8 col-lg-7'>
            <div className='single__template__title fw-bold'>{title}</div>
            <div>
            <div className='single__template__disc'>{description}</div>
            <div className='template__read fw-bold'  onClick={(e)=>seemore(e)}>Read More ... </div>
           
            <div className='template__read fw-bold d-none'  onClick={(e)=>seemore(e)}>Read less ... </div>
            </div>
            </div>
            <div className='single__template__links d-flex justify-content-between align-items-center col-lg-2 flex-lg-column'>
                <div className='single__template__price fw-bold text-capitalize px-3 p-lg-4'>{price}</div>
                <div className='text-center d-flex my-3 m-lg-0 flex-lg-column'>
                <a className='single__template__link py-1 px-2 mx-2 mb-lg-2' href={preview} target="_blank">Live Preview</a>
                <a className='single__template__link py-1 px-3' href={download} target="_blank">Download</a>
                </div>
            </div>
            </div>
        </div>




    </div>
  )
}

export default SingleTemplate
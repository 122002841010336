import React from 'react'
import "./UsefullLInks.css";

function UsefillSingle({image,title,description,link,website}) {
    function myFunction() {
        var input, filter, ul, li, a, i, txtValue, data;
        input = document.getElementById("myInput");
    
        let list = document.querySelector(".usefull_list");
        let single = list && list.getElementsByClassName("single_link");
    
        filter = input.value.toUpperCase();
    
        for (i = 0; i < single.length; i++) {
          txtValue = single[i].textContent;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            single[i].style.display = "";
          } else {
            single[i].style.display = "none";
          }
        }
      }
    
  return (
    <div>
           
     
      
        <div className="single_link row">
          <img
            className="single_link_thumb d-none d-md-block col-4 col-lg-3 col-xl-2"
            src={image && image}
          />
          <div className="col-12  col-md-8 col-lg-9 col-xl-10 g-0 d-flex flex-column justify-content-between">
            <div className="single_link_head row">
              <img
                className="single_link_thumb col-3 d-md-none"
                src={image && image}
              />
              <div className="single_link_title text-uppercase fw-bold col-8 col-md-12">
                {title && title}
                
              </div>
            </div>
            <div className="single_link_desc col-12">
              {description && description}
            </div>
            <a className="single_link_url col-12" href={link} target="_blank">
              <div className="single_link_btn">
                
                Click to Visit
                <span className="single_url px-2">{website && website}</span>
              </div>
            </a>
          </div>
        </div>


 
      </div>
    
  )
}

export default UsefillSingle
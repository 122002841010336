import React, { useEffect, useState } from "react";
import UserHead from "../User/UserHead";
// import { Table } from 'react-bootstrap/Table'
import Table from "react-bootstrap/Table";
import "./TutorReport.css";
import CreateMeeting from "./CreateMeeting";

import "bootstrap/dist/js/bootstrap.bundle.min";
import $ from "jquery";
import Popper from "popper.js";


import ModalContent from "../Modal/Modal";
import StudentMeetingReport from "./StudentMeetingReport";
import MeetingReport from "./MeetingReport";
import { getMultiFactorResolver, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import NewTraineeWait from "./Registrar/NewTraineeWait";


function TutorReport() {


  const [reports, setreports] = useState([]);
  const [repo, setrepo] = useState([]);
  const [currentuser, setcurrentuser] = useState("");
  const [users, setusers] = useState("");
  const [students, setstudents] = useState("");
  const [role, setrole] = useState("");
  const [studentlist, setstudentlist] = useState("");
  const [user_status, setuser_status] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setcurrentuser(email);
      } else {
     
      }
    });
  }, []);
  //daily report table fetch
  useEffect(() => {
    fetch(process.env.REACT_APP_DAILYREPORT_FETCH)
      .then((response) => response.json())
      .then((data) => {
        
        if (data) {
          setrepo(data);
        } else {
          // console.log(err)
        }
      });
  }, []);

  useEffect(() => {
    setreports([...repo].sort().reverse());
  }, [repo]);
  //users table fetch
  useEffect(() => {
    fetch(process.env.REACT_APP_USERSFETCH)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setusers(data);
        } else {
          // console.log(err)
        }
      });
  }, []);
  //students table fetch
  useEffect(() => {
    fetch(process.env.REACT_APP_STUDENTS_FETCH)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setstudents(data);
          // console.log(data);
        } else {
          // console.log(err)
        }
      });
  }, []);

//users list  map setrole and setuserstatus
  useEffect(() => {
    users &&
      users.map((data) => {
        if (data.email === currentuser) {
          setrole(data.role);
          setuser_status(data.user_status);
        }
      });
  });

//trainer checker function
  const checker = () => {
    const list = [];
    students &&
      students.map((student) => {
        if (currentuser === student.tutoremail && role === "trainer") {
          list.push(student.studentemail);
        }
      });
    return list;
  };

  const checktrainer = (test) => {
    if (role === "trainer") {
      return test;
    }
  };
//name displayer function
  const name = (email) => {
    let zname = "";
    students &&
      students.map((student) => {
        if (email === student.studentemail) {
          zname = student.fname + " " + student.lname;
        } else {
          return "no name";
        }
      });
    return zname;
  };
  let gggg=<textarea id='message-box'></textarea>
  let hhhh=gggg.toString()
  console.log(hhhh)
  console.log(typeof(gggg.toString()))

  return (
    <div className="tutorReport  container justify-content-center ">
      
      <div>

      {user_status === "new" ? (
        <div>
          <NewTraineeWait />
         
        </div>
      ) : (
        <div>
          <div className="tutorReport__content"></div>
          
          <div className="tutorReport__content__title">
          
          personal tutoring program daily report
          </div>
          
          <div className="creater">{role==="trainer"?<CreateMeeting/>:""}</div>
          

          <div className="tutorReport__content__table text-center  ">
            <div className="">
              {repo &&
                reports &&
                reports.map((report) => {
                  
                  for (let index = 0; index < checker().length; index++) {
                    const element = checker()[index];
                    if (checker()[index] === report.student_email)
                    
                      return (
                        <MeetingReport
                          key={report.dailyReport_id}
                          student_name={name(report.student_email)}
                          email={report.student_email}
                          start_time={report.start_time}
                          Meeting_Link={report.Meeting_Link}
                          meetingdate={report.meetingdate}
                          end_time={report.end_time}
                          today_plan={report.today_plan}
                          lessons_covered={report.lessons_covered}
                          next_day_plan={report.next_day_plan}
                          comment={report.comment}
                          tutor_comment={report.tutor_comment.toString()}
                          report_status={report.report_status}
                          
                        />
                      );
                  }
                })}

              {/* {users && users.map((use)=>{
            if(1===1){ */}
              {repo &&
                reports &&
                reports.map((report) => {
                  if (currentuser === report.student_email) {
                    return (
                      <StudentMeetingReport
                        key={report.dailyReport_id}
                        email={report.student_email}
                        start_time={report.start_time}
                        Meeting_Link={report.Meeting_Link}
                        meetingdate={report.meetingdate}
                        end_time={report.end_time}
                        today_plan={report.today_plan}
                        lessons_covered={report.lessons_covered}
                        next_day_plan={report.next_day_plan}
                        comment={report.comment}
                        tutor_comment={report.tutor_comment}
                        report_status={report.report_status}
                   
                      />
                    );
                  }
                })}
            
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
}

export default TutorReport;

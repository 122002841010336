import { async } from "@firebase/util";
// import { getDoc, getDocs, query, orderBy, limit } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "./JoinModal/JoinModal.css";
import { Link } from "react-router-dom";
import {
  addDoc,
  collection,
  query,
  getDocs,
  where,
  getDocslimit,
  orderBy,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import Project from "../Project";
import "./ConfirmedProjects.css";
import { useStateValue } from "../../StateProvider/StateProvider";
import { onAuthStateChanged } from "firebase/auth";
import SignInFirstModal from "../../Sign/SIgnInFirstModal/SignInFirstModal";
import JoinModal from "./JoinModal/JoinModal";
import Loader from "../../Loader/Loader";

function ConfirmedProjects() {
  const [{ basket, user,loading }, dispatch] = useStateValue();
  const [currentuser, setcurrentuser] = useState("");
  const [titlejoined, settitlejoined] = useState("");
  const [prodata, setprodata] = useState("");
  const [prodatasorted, setprodatasorted] = useState("");
  const [checker, setchecker] = useState([]);
  const [permission, setpermission] = useState([]);
  const [isShow, invokeModal] = useState(false);
  // const[status,setstatus]=useState("loading");
  let status="loading"
  useEffect(() => {
    db.collection("hosting_form")
      .orderBy("st_date")
      .onSnapshot((snapshot) =>
        setprodata(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
      

  }, []);


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setcurrentuser(email);
      } else {
        setcurrentuser("Sign IN");
      }
    });
  });
  // const currentDate = new Date();

  // -------------------------------------------------------------------
  // ---------------join project start----------------------------------

  // ---------------join project start----------------------------------
  // -------------------------------------------------------------------
  const closeModal = () => {
    return invokeModal(false);
  };
  // selectproject
  async function joinproject(e) {
    e.preventDefault();
    let title = e.target.value;
    // await e.target.value
    const titlejoined = e.target.value;
    await settitlejoined(e.target.value);
    await console.log(titlejoined);

    const lister = () => {
      db.collection("projects")
        .doc("waiting")
        .collection(titlejoined)
        .doc("data")
        .collection("participants")
        .onSnapshot((snapshot) =>
          setchecker(
            snapshot.docs.map((doc) => ({
              // id: doc.id,
              data: doc.data(),
            }))
          )
        );
    };
    await lister();

    return invokeModal(!false);
  }

  async function joinproject2(e) {
    await joinproject(e);
    let permissions = "approved";

    checker &&
      checker.map((item) => {
        let list = item.data.email;
        if (list.indexOf(currentuser) > -1) {
          permissions = "denied";
        } else {
          permissions = "approved";
        }
      });

    if (permissions === "denied") {
      alert("you are already joined for this project");
    } else {
      const docRef = doc(db, "projects", "waiting", titlejoined, "data");
      const colRef = collection(docRef, "participants");
      addDoc(colRef, {
        title: titlejoined,
        email: currentuser,
      });
      alert(`congratulations you are joined to ${titlejoined}`);
    }

    await closeModal();
  }
               
  
  return (
    <div className="container confirm">
      {/* {loading?<Loader/>:""} */}
      <p className="confirm_title">UPCOMING PROJECTS</p>
      
     

      <div className="item">
        {prodata &&
          prodata.map((prodata) => {
            const currentDate = new Date();
            const startingDate = new Date(prodata.data.st_date);
            const cdate = currentDate.getTime();
            const sdate = startingDate.getTime();
           
            
            if (prodata.data.status === "confirmed") {
              if (sdate >= cdate) {
                 
                return (
                  <div key={prodata.id}>
                    <div class="item_single">
                      <div class="confirmed_main">
                       
               
                        <Project
                          Project_title={prodata.data.Project_title}
                          st_date={prodata.data.st_date}
                          schedule={prodata.data.day1}
                          fname={prodata.data.fname}
                          lname={prodata.data.lname}
                          day1={prodata.data.day1}
                          time1={prodata.data.time1}
                          day2={prodata.data.day2}
                          time2={prodata.data.time2}
                          
                        />
                      </div>

                      <div class="confirmed_details ">
                        {/* <form onSubmit={join}> */}
                        <table className="w-100">
                          <thead>
                            <th>Details for {prodata.data.Project_title}</th>
                          </thead>
                          <tbody>
                            <td>Project Title</td>

                            <td>{prodata.data.Project_title}</td>
                          </tbody>
                          {prodata.data.Project_link && (
                            <tbody>
                              <td>Project Link</td>
                              <td>{prodata.data.Project_link}</td>
                            </tbody>
                          )}
                          {prodata.data.github_link && (
                            <tbody>
                              <td>Github Link</td>
                              <td>{prodata.data.github_link}</td>
                            </tbody>
                          )}
                          {prodata.data.st_date && (
                            <tbody>
                              <td>Starting Date</td>
                              <td>{prodata.data.st_date}</td>
                            </tbody>
                          )}
                          {prodata.data.day1 && (
                            <tbody>
                              <td>Weekly Schedule Day 1</td>
                              <td>
                                {prodata.data.day1} at {prodata.data.time1} EST
                                (washington DC time)
                              </td>
                            </tbody>
                          )}
                          {prodata.data.day2 && (
                            <tbody>
                              <td>Weekly Schedule Day 2</td>
                              <td>
                                {prodata.data.day2} at {prodata.data.time2} EST
                                (washington DC time)
                              </td>
                            </tbody>
                          )}
                          {prodata.data.project_delay && (
                            <tbody>
                              <td>Estimated Project Delay</td>
                              <td>{prodata.data.project_delay} Weeks</td>
                            </tbody>
                          )}
                          {prodata.data.Project_description && (
                            <tbody>
                              <td>Project Description</td>
                              <td>{prodata.data.Project_description}</td>
                            </tbody>
                          )}
                          {prodata.data.Project_des && (
                            <tbody>
                              <td>Design Files</td>
                              <td>{prodata.data.Project_des}</td>
                            </tbody>
                          )}
                          {prodata.data.files_associated && (
                            <tbody>
                              <td>Files associated to the project</td>
                              <td>{prodata.data.files_associated}</td>
                            </tbody>
                          )}
                          {prodata.data.fname && (
                            <tbody>
                              <td>Host </td>
                              <td>
                                {prodata.data.fname} {prodata.data.lname}
                              </td>
                            </tbody>
                          )}
                        </table>

                        {!user ? (
                          <SignInFirstModal
                            className="aim_link "
                            button_text={
                              <p className="joinbtn-mod">JOIN NOW !!</p>
                            }
                          />
                        ) : (
                          <div>
                            <Button
                              value={prodata.data.Project_title}
                              variant=""
                              className="text-white joinbtn fw-bold"
                              onClick={joinproject}
                            >
                              JOIN NOW !!
                            </Button>
                            <Modal show={isShow}>
                              <Modal.Body className="modalbox-body">
                                <div className="modal-content-centered">
                                  <div className="modal-content-text">
                                    you are about to join to {titlejoined}
                                  </div>

                                  <div className="modal-buttons">
                                    <button
                                      className="modal-btn bg-success"
                                      value={prodata.data.Project_title}
                                      // value={Project_link}
                                      // onClick={join1} onMouseOver={join}  onMouseOut={out}>JOIN NOW !! </button>
                                      onClick={joinproject2}
                                    >
                                      Confirm{" "}
                                    </button>
                                    <button onClick={closeModal} className="modal-btn bg-danger">
                cancel
              </button>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        )}

                        {/* </form> */}
                      </div>

                      {/* <div class="hr">
              <div class="hr_hr"><hr></hr></div></div> */}
                    </div>
                  </div>
                );
                
              }
            }
          })}
      </div>
    </div>
  );
  
}

export default ConfirmedProjects;

import React, { useEffect, useState,useCallback,useMemo } from "react";
import { Link } from "react-router-dom";
import "./HostingForm.css";
import { getDocs, addDoc, collection } from "firebase/firestore";
import{coll, db} from "../../../firebase"
import DropdownList from "react-widgets/DropdownList";
import { useStateValue } from "../../StateProvider/StateProvider";

import {useDropzone} from 'react-dropzone'
import ReactDOM from "react-dom";

// import { FileUploader } from "react-drag-drop-files";

// const fileTypes = ["JPG", "PNG", "GIF"];


function HostingForm() {
  const [{basket,user}, dispatch]=useStateValue();
  const[userdata,setuserdata]=useState("")
  const [Project_title, setProject_title] = useState("");
  const [Project_link, setProject_link] = useState("");
  const [github_link, setgithub_link] = useState("");
  const [st_date, setst_date] = useState("");
  const [st_timezone, setst_timezone] = useState("");
  const [day1, setday1] = useState("");
  const [time1, settime1] = useState("");
  const [day2, setday2] = useState("");
  const [time2, settime2] = useState("");
  const [project_delay, setproject_delay] = useState("");
  const [Project_description, setProject_description] = useState("");
  const [Project_des, setProject_des] = useState("");
  const [files_associated, setfiles_associated] = useState("");
  const [status,sestatus]=useState("pending")
  const[fname,setfname]=useState("")
  const[lname,setlname]=useState("")

 
 const handleSubmit = (e) => {
  e.preventDefault()
 addDoc(collection(db, "hosting_form"), {
  Project_title:Project_title,
  Project_link:Project_link,
  github_link:github_link,
  st_date:st_date,
  st_timezone:st_timezone,
  day1:day1,
  time1:time1,
  day2:day2,
  time2:time2,
  project_delay:project_delay,
  Project_description:Project_description,
  Project_des:Project_des,
  files_associated:files_associated,
  status:"pending",
  email:user.email,
  fname:fname,
  lname:lname,
  zoom_link:""
})

  .then(() => {
    alert("Thank you for being part of our program, Your project sucessfuly registered, the admins will review and contact you to some data improvements, once you confirm on informations we modified it will be available for public to join ");
  })
  .catch((error) => {
    alert(error)
    // console.log(error.message);
  });
};

const [requests, setrequests] = useState([]);


useEffect(()=>{
  db.collection("users").onSnapshot((snapshot)=>
  setuserdata( snapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  })
  ))
  )
  // .then((e)=>{
  //   alert("userdatamodified")
  // })
},[]);
useEffect(()=>{
  {userdata &&
    userdata.map((userdata) => {
      if(user?.email===userdata.data.email){
        setfname(userdata.data.fname)
        setlname(userdata.data.lname)
      }
      
    })
      
  
  // if(userdata.data.email===user.email){
  //   alert("vola")
  // }
}})



  return (
    <div className="hostingform container">
     
      <div className="hostingform__head">New Project Hosting Form</div>
      <form onSubmit={handleSubmit} >
        <div className="full">
          <p>Project Title</p>
          <input label="Project_title" placeholder="Title"
          value={Project_title}
          onChange={(e) => setProject_title(e.target.value)} required ></input>
        </div>
        <div className="full">
          <p>
            Project Link <span className="note">(optional)</span>
          </p>
          <input
            label="Project_link"
            placeholder="If it deployed previously or if the project is clone of live site"
            value={Project_link}
          onChange={(e) => setProject_link(e.target.value)} 
          ></input>
        </div>
        <div className="full">
          <p>
            Github Link <span className="note">(optional)</span>
          </p>
          <input
            label="github_link"
            placeholder="If the project previously pushed to Git repository"
            value={github_link}
          onChange={(e) => setgithub_link(e.target.value)} 
          ></input>
        </div>
        <div className="items">
          <p>Starting Date</p>
          <input label="st_date" placeholder="Date" type="date" min="2022-01-01"
          value={st_date}
          onChange={(e) => setst_date(e.target.value)}
          ></input>
          {/* <input label="st_timezone" placeholder="Time Zone" type=""
          value={st_timezone}
          onChange={(e) => setst_timezone(e.target.value)} ></input> */}
        </div>
        <div className="items">
          <p className="d-flex">Time is Zone ET(Washington,DC time) for time reference use <a className="timezoneConvert "href="https://time.is/ET" target="_blank">Time zone reference</a></p>
          <p>Weekly Schedule Day 1</p>
          <div className="d-flex">


          {/* <input label="day1" placeholder="Day-1" type="date"
          value={day1}
          onChange={(e) => setday1(e.target.value)} 
          ></input> */}
          <form value={day1}
          onChange={(e) => setday1(e.target.value)}>
            <select name="days">
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednsday">Wednsday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </form> 
          <input label="time1" placeholder="Time" type="time"  step="0" defaultValue="07:00"
          value={time1}
          onChange={(e) => settime1(e.target.value)} 
          ></input>
          </div>
        </div>
        <div className="items">
          <p>Weekly Schedule Day 2 <span className="note">(optional)</span></p>
          <div className="d-flex">
          {/* <input label="day2"  type="date"
          value={day2}
          onChange={(e) => setday2(e.target.value)} ></input> */}
          
          <form label="day2"  
          value={day2}
          onChange={(e) => setday2(e.target.value)}>
            <select name="days">
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednsday">Wednsday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </form>
          
          
          
          <input label="time2" placeholder="Time" type="time"
          value={time2}
          onChange={(e) => settime2(e.target.value)} ></input>
        </div>
        </div>
        <div className="items">
          <p>
            Estimated Project Delay <span className="note">(optional)</span>
          </p>
          <input
            label="project_delay"
            placeholder="Weeks"
            type="number"
            value={project_delay}
          onChange={(e) => setproject_delay(e.target.value)}
          ></input>
        </div>
        <div className="full">
          <p>Project Description</p>
          <textarea
            label="Project_description"
            placeholder="project description"
            rows="5"
            value={Project_description}
          onChange={(e) => setProject_description(e.target.value)}
          ></textarea>
        </div>


{/* =====================================================================
design drag and drop
===================================================================== */}



{/* 
        <div>
          <p>
            Web page design Images <span className="note">(optional)</span>
          </p> */}


{/* 
<div className="drop-zone">
  <FileUploader  handleChange={handleChange} name="file" types={fileTypes}  id="drop-zone"/>
  </div>

          <input
            placeholder="Drag and drop files OR"
            type="file"
            value={Project_des}
          onChange={(e) => setProject_des(e.target.value)}
          ></input>
              
          <p>
            Additional files related to this project{" "}
            <span className="note">(optional)</span>
          </p>
          <input
            placeholder="files associated to this project"
            type="file"
            value={files_associated}
          onChange={(e) => setfiles_associated(e.target.value)}
          ></input>
        </div> */}

        <div>
          <input
            className="apply"
            label="submit"
            type="submit"
          ></input>
        </div>
      </form>
    </div>
  );
}

export default HostingForm;

import React from 'react'
import "./SignIn.css"
import {BsPersonFill, BsFillTelephoneFill} from "react-icons/bs"
import {MdEmail} from "react-icons/md"
import {RiLockPasswordFill} from "react-icons/ri"
import { Link, Navigate, unstable_HistoryRouter, useNavigate } from 'react-router-dom'
import {useState} from "react"
import {auth} from "../../../firebase"
import Swal from 'sweetalert2'

function SignIn() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [password, setpassword] = useState("");
  


  const signIn = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
                navigate("/")
        
      })
      .catch((error) =>Swal.fire({
        icon: 'error',
        text: 'The email or Password you entered is incorrect',
        confirmButtonColor: '#F0850C',
        
      }))
  };

  
  return (
    <div className='container'>
    <form onSubmit={signIn} >
        <div className="signin__right__title ">Log in to your account </div>
        <div className="signup__right__email in">
          <MdEmail/><input name="email" placeholder="E-mail" type="email" autocomplete="on" value={email}
          onChange={(e) => setEmail(e.target.value)} required></input>
        </div>
        <div className="signup__right__password in">
          <RiLockPasswordFill/> <input name="password" placeholder="Password" type="password" autocomplete="current-password" value={password}
          onChange={(e) => setpassword(e.target.value)} required></input>
        </div>
        <div >
          <button className="signup__right__signup" type="submit" >SIGN IN</button>
        </div>
        
    </form>
    </div>
  )
}

export default SignIn
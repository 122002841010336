import React from "react";
import "./Loader.css";
import logo from '../../resources/images/Andmta_logo.png'

function Loader() {
  return (
    <div className="d-flex preloader">
      {/* <div className="spinner py-2">
        <div className="bounce1"></div>
        <div className="bounce3"></div>
        <div className="bounce3"></div>
      </div>
      <div className="spinner2 py-2">
        <div className="bounce2"></div>
        <div className="bounce2  middle"></div>
        <div className="bounce1"></div>
      </div>
      <div className="spinner py-2">
        <div className="bounce1"></div>
        <div className="bounce3"></div>
        <div className="bounce2"></div>
      </div> */}
        <div className="parent">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>

        <div className="bounce8"></div>
        <div className="bounce9"><img className="loader-logo"src={logo}/></div>
        <div className="bounce4"></div>

        
       
        <div className="bounce7"></div>
        <div className="bounce6"></div>
        <div className="bounce5"></div>
        
        </div>


    </div>
  );
}

export default Loader;

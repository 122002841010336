import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import "./AdminMenu.css";

function AdminMenu() {
  return (
    <div>
      <div className="adminmenu">
        <div className="adminmenu__header">
          <Navbar expand="md">
            <Navbar.Toggle></Navbar.Toggle>
            <Navbar.Collapse>
              <Nav className=" adminmenu__header__navs ">
              <Nav.Link>
                  <Link to="/admin">Admin Home</Link>
                </Nav.Link>
                <NavDropdown title="Project Data" id="navbarScrollingDropdown">
                  <NavDropdown.Item>
                    
                      <Link to="/UpcomingProjects">Upcoming Projects</Link>
                    
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    
                      <Link to="/">Active Projects</Link>
                    
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                  <Link to="/">Previous Projects</Link>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Divider /> */}
                </NavDropdown>
                <Nav.Link>
                  <Link to="/">User Data</Link>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/#">Tutoring Data</Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
}

export default AdminMenu;

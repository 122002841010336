import React from 'react'
import './Documents.css'
import thumb from '../../../../resources/documents/thumbnails/book-covers-big-2019101610.jpg-1300.jpg'
import html from '../../../../resources/documents/thumbnails/html cover web.jpg'
import css from '../../../../resources/documents/thumbnails/css cover web.jpg'
import htmlpdf from '../../../../resources/documents/Basics of HTML (ED-01).pdf'
import csspdf from '../../../../resources/documents/Basics Of  CSS(ED 01) (andmtaskills.com).pdf'

function Documents() {
  return (
    <div className='documents container'>
        <div className='single_document row g-0 mb-3 align-items-center'>
            <div className='single_document_thumbnail col-sm-4 col-lg-3'><img className="single_document_thumbnail_image w-100"src={html}/></div>
            <div className='single_document_detail col-sm-8 col-lg-9  d-sm-flex flex-column justify-content-between'>
            <div className='single_document_detail_top '>

            <div className='single_document_detail__title'>Basics Of Html</div>
            <div className='single_document_detail__disc'>This document is about the basic concepts of HTML. Commonly used HTML tags like meta tags, text tags, link tags, image tags, list tags, table tags, form tags, scripting tags, and also HTML attributes covered in the document.</div>
            <div className='single_document_detail__disc'>Practice exersise also included in the document </div>
            </div>
            <div className='single_document_detail__links d-flex justify-content-end pb-3'>
            <div className='single_document_detail__practice'>
                <a className="single_document_link" href="https://andmtahtmlpractice.netlify.app/" target="_blank">Practice</a>
            </div>
            <div className='single_document_detail__download'>
                <a className="single_document_link" href={htmlpdf} target="_blank">Download</a>
            </div>
            </div>
            </div>
        </div>
        <div className='single_document row g-0 mb-5 align-items-center'>
            <div className='single_document_thumbnail col-md-4 col-lg-3'><img className="single_document_thumbnail_image w-100"src={css}/></div>
            <div className='single_document_detail col-md-8  col-lg-9 d-sm-flex flex-column justify-content-between'>
            <div className='single_document_detail_top '>

            <div className='single_document_detail__title'>Basics Of CSS</div>
            <div className='single_document_detail__disc'>This document is about the Basic concepts of CSS. Methods to use universal, class, id, descendant, child, and attribute selectors to give properties inside CSS, CSS inclusion, overriding CSS rules, inheritance, basic measurement units, how to use colors and get the value of any color, box model, background, text, link, list, cursor styling, flexbox, and grid, positioning, and other styling techniques included in the pdf. </div>
            </div>
            <div className='single_document_detail__disc'>Practice exersise also included in the document </div>
            <div className='single_document_detail__links d-flex justify-content-end pb-3'>
            <div className='single_document_detail__practice'>
                <a className="single_document_link" href="https://andmtacsspractice1.netlify.app/" target="_blank">Practice</a>
            </div>
            <div className='single_document_detail__download'>
                <a className="single_document_link" href={csspdf} target="_blank">Download</a>
            </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Documents
import React from "react";
import "../StudyHead/StudyHead.css";
import time from "../../../resources/images/save_time_icon.png";
import shedule from "../../../resources/images/select_icon.png";
import one from "../../../resources/images/one_to_one_icon.png";

function StudyHead() {
  return (
    <div className="studyhead ">
      <div className="studyhead__left">
        <p className="p-2">STUDY WITH ANDMTA </p>
        <div className="studyhead__left__see" >see more</div>
      </div>
      <div className="studyhead__right">
        
        <div className="studyhead__right__item">
          <img src={shedule}></img>
          <div>Select Your Prefered Schedule</div>
        </div>
        <div className="studyhead__right__item">
          <img src={time}></img>
          <div>Save Your Golden Time</div>
        </div>
        <div className="studyhead__right__item">
          <img src={one}></img>
          <div>One to One Online Training</div>
        </div>
      </div>
      <p className="studyhead__right__see">See More</p>
    </div>
  );
}

export default StudyHead;

import { addDoc, collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../../firebase";
import { useStateValue } from "../../StateProvider/StateProvider";
import "./PersonalTrainingRequest.css";
import CountryData from "./CountryData.json";
import { v4 as uuidv4 } from "uuid";
import { onAuthStateChanged } from "firebase/auth";
import Schedule from "../../Report/Registrar/Schedule";

function PersonalTrainingRequest() {
  const [{basket,user}, dispatch]=useStateValue();
const[school,setschool]=useState("")
const[status,setstatus]=useState("")
const[batch,setbatch]=useState("")
const[phone,setphone]=useState("")
const[day1,setday1]=useState("")
const[time1,settime1]=useState("")
const[day2,setday2]=useState("")
const[time2,settime2]=useState("")
const [phoneNum, setphoneNum] = useState("");
const[userdata,setuserdata]=useState([])
const [fname,setfname]=useState("");
const [lname,setlname]=useState("");


//schedule functions start
const [users, setusers] = useState([]);
const [schedule, setschedule] = useState("");
const [tutoremail, settutoremail] = useState("");
const [trainername, settrainername] = useState("");
const [dayone, setdayone] = useState("");
const [timeone, settimeone] = useState("");
const [daytwo, setdaytwo] = useState("");
const [timetwo, settimetwo] = useState("");
const [monday, setmonday] = useState("");
const [tuesday, settuesday] = useState("");
const [wednsday, setwednsday] = useState("");
const [thursday, setthursday] = useState("");
const [friday, setfriday] = useState("");
const [saturday, setsaturday] = useState("");
const [sunday, setsunday] = useState("");
const [zone, setzone] = useState("");


//users table fetcher
useEffect(() => {
    fetch(process.env.REACT_APP_USERSFETCH)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          
          setusers(data);
        } else {
          
        }
      });
  }, []);
//schedule table fetcher
useEffect(() => {
  fetch(process.env.REACT_APP_SCHEDULE_FETCH)
    .then((response) => response.json())
    .then((data) => {
      setschedule(data);
    });
}, []);
// full schedule state setter
const fullsch = (tutoremail) => {
  schedule &&
    schedule.map((data) => {
      if (tutoremail === data.user_email) {
        console.log(data.tues);
        setmonday(data.mon);
        settuesday(data.tues);
        setwednsday(data.wed);
        setthursday(data.thur);
        setfriday(data.fri);
        setsaturday(data.sat);
        setsunday(data.sun);
      }
    });
};

// email selector
const emailer = (val) => {
  settutoremail(val);
  fullsch(val);
};
//time converter display
const timeConverter=(val,zone)=>{

  const splitt=val.split(':')
  const zoner=()=>{
    if(zone==="DC"){
      if(splitt[0]-8>0){return splitt[0]-8}else {return 50}
    }else{return splitt[0]}
  }
  if(zoner()==0){
    return( 12  + ":"+splitt[1]+ " "+"AM")
    
  }else if( zoner()==50){
    return("")
    // return( 12  + ":"+"00")
  }else if( zoner()==24){
    
    return( 12  + ":"+"00")
  }else if( zoner()==12){
    
    return( 12  + ":"+splitt[1]+ " "+"PM")
  }else if(zoner()>12){
    
    let converted=zoner()%12 + ":"+splitt[1] + " "+ "PM"
    return converted
  
  }else{
    return( zoner()  + ":"+splitt[1]+ " "+"AM")
  }
  

}


//trainer name setter
const trainer=(email)=>{
  let zname=""
users && users.map((data)=>{
//  zname=data.fname
if(data.email===email){
 zname=data.fname+" "+data.lname

}
})
return zname
}

// profile button setter
const profiler=(email)=>{
  let profile=""
  users && users.map((data)=>{
  //  zname=data.fname
  if(data.email===email){
     profile=data.profile
  }
  })
  return profile
}
//schedule functions end



useEffect(() => {
  db.collection("users")
    
    .onSnapshot((snapshot) =>
      setuserdata(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      )
    );
}, []);
useEffect(()=>{
  userdata?.map((item)=>{
    const email=user?user.email:""
    
    if(item.data.email===email){
      setfname(item.data.fname)
      setlname(item.data.lname)

    }

  })
})


const handleSubmit = (e) => {
  e.preventDefault()
  // scheduler()
  onAuthStateChanged(auth, (user) => {
    if (user) {
 addDoc(collection(db, "trainee"), {
  school:school,
  student_status:status,
  batch:batch,
  // day1:day1,
  // time1:time1,
  // day2:day2,
  // time2:time2,
  day1:dayone,
  time1:timeone,
  day2:daytwo,
  time2:timetwo,
  email:user.email,
  phoneNum: phoneNum,
  code: searchCountry.dial_code,
  fname:fname,
  lname:lname,

})

  
  .then(()=>{

    
      let form2 = document.querySelector(".form");
      form2.action = process.env.REACT_APP_REGISTER_NEW_TRAINEE;
      form2.method = "post";
      form2.submit()
      
  })
        
    // alert("Your request has been submitted we will contact you via whatsapp")
    // window.location.reload();
  // })
  // }else{alert("please Sign in first")
  // window.location.reload();
}})
}
console.log(monday)


const [countries, setCountries] = useState(CountryData);
  // console.log("countries", countries);

  const [searchCode, setSearchCode] = useState();

  const searchCountry = countries.find((obj) => {
    if (obj.code === searchCode) {
      return true;
    }
    return false;
  })


  return (
    <div className="personalform container">
      <div className="personalform__head">
        New Personal Training Application Form
      </div>
      {/* <div><Schedule/></div> */}
      <form onSubmit={handleSubmit} class="form">
        <div class="forsql d-none">
          <input id="fname" name="fname" value={fname}></input>
          <input id="lname" name="lname" value={lname}></input>
          <input id="role" name="role"  value="student"></input>
          <input id="user_status" name="user_status" value="new"></input>
          <input id="starting_date" name="starting_date" value={new Date()}></input>
          <input id="email" name="email" value={user&& user.email}></input>
        </div>
        {/* <div><Schedule/></div> */}
        
        <div className="schedule_div">
        <div>
       
       <div className="trainer_section">
       <div className="selected_trainer  d-flex d-none ">You have selected {trainer(tutoremail)} as your personal trainer  <div className="view_profile">{tutoremail && profiler(tutoremail) &&<a href={profiler(tutoremail)} target="_blank">View Profile</a>}
       </div></div>
       <div className="select_trainer d-sm-flex">
       <p className="title ">Select Trainer</p>
       <select
       className=""
         name="user_email"
         id="user_email"
         onChange={(e) => emailer(e.target.value)}
       >
         <option value="" selected disabled>
           select your trainer
         </option>
         {schedule &&
           schedule.map((data) => {
             const emails = data.user_email;
             return <option className="text-capitalize" value={emails}>{trainer(data.user_email)}</option>;
           }, [])}
       </select>
       
       <div className="view_profile">{tutoremail && profiler(tutoremail) &&<a href={profiler(tutoremail)} target="_blank">View Profile</a>}</div>
       </div>
       </div><br></br>
       <div className="d-sm-flex">
       <p className="title ">Select time zone</p>
       <select className="col-auto" name="zone" id="zone" onChange={e=>setzone(e.target.value)}>
         <option value="ET">Addis Ababa, Ethiopia(GMT+3) Time </option>
         <option value="DC">Washington DC,USA (GMT-5) Time</option>
         
       </select>
       </div>
       <div className="dayone-section ">
       <p className="">Set Day one</p> <span className="dayone d-none">{dayone}</span>
       {
         <select
         className="selectone"
           name="dayone"
           id="dayone"
           onChange={(e) => setdayone(e.target.value)}
         >
           <option value="" selected disabled>
             select day one{" "}
           </option>
           <option value="monday">monday</option>
           <option value="tuesday">tuesday</option>
           <option value="wednsday">wednsday</option>
           <option value="thursday">thursday</option>
           <option value="friday">friday</option>
           <option value="saturday">saturday</option>
           <option value="sunday">sunday</option>
         </select>
       }
      
       {schedule &&
         schedule.map((data) => {
           const daysnum = [
             "monday",
             "tuesday",
             "wednsday",
             "thursday",
             "friday",
             "saturday",
             "sunday",
           ];
           const setdaysnum = [
             setmonday,
             settuesday,
             setwednsday,
             setthursday,
             setfriday,
             setsaturday,
             setsunday,
           ];
 
           const indexnum = dayone && daysnum.indexOf(dayone);
           let email = data.user_email;
           if (dayone && email === tutoremail) {
             const mon = data.mon.split(",");
             const tues = data.tues.split(",");
             const wed = data.wed.split(",");
             const thur = data.thur.split(",");
             const fri = data.fri.split(",");
             const sat = data.sat.split(",");
             const sun = data.sun.split(",");
 
             const daysnum = [mon, tues, wed, thur, fri, sat, sun];
             const arr = daysnum[indexnum];
           
 
             const splicer = (day, val) => {
             
              settimeone(val)
             
               let v = day.indexOf(val);
               day.splice(v, 1);
               setdaysnum[indexnum](day);
               const noclass=document.querySelector(".red")
               const zclass=document.querySelector(`.selected${v}`)
               const selectone=document.querySelector(".selectone")
               const dayone=document.querySelector(".dayone")
               const select_trainer=document.querySelector(".select_trainer")
               const selected_trainer=document.querySelector(".selected_trainer")
               selectone.classList.add("d-none")
               select_trainer.classList.remove("d-sm-flex")
               select_trainer.classList.add("d-none")
               dayone.classList.remove("d-none")
               selected_trainer.classList.remove("d-none")
               noclass && noclass.classList.remove("red")
               zclass.classList.add("red")
             };
             let newmon = [];
             for (let index = 0; index < arr.length; index++) {
               newmon.push(
                 <button className={`times selected${index}`}
                   type="button"
                   
                   defaultValue={arr[index]}
                   onClick={(e) => splicer(arr, arr[index])}
                 >{timeConverter(arr[index],zone)}</button>
               );
             }
 
             return newmon;
           }
         }, [])}
 </div>
 
       <div className="daytwo-section">
       <p>Set Day two</p> <span className="daytwo d-none">{daytwo}</span>
       {
         <select
         className="selecttwo"
           name="daytwo"
           id="daytwo"
           onChange={(e) => setdaytwo(e.target.value)}
         >
           <option value="" selected disabled>
             select day two{" "}
           </option>
           <option value="monday">monday</option>
           <option value="tuesday">tuesday</option>
           <option value="wednsday">wednsday</option>
           <option value="thursday">thursday</option>
           <option value="friday">friday</option>
           <option value="saturday">saturday</option>
           <option value="sunday">sunday</option>
         </select>
       }
      
       {schedule &&
         schedule.map((data) => {
           const daysnum = [
             "monday",
             "tuesday",
             "wednsday",
             "thursday",
             "friday",
             "saturday",
             "sunday",
           ];
           const setdaysnum = [
             setmonday,
             settuesday,
             setwednsday,
             setthursday,
             setfriday,
             setsaturday,
             setsunday,
           ];
 
           const indexnum = daytwo && daysnum.indexOf(daytwo);
           let email = data.user_email;
           if (daytwo && email === tutoremail) {
             const mon = data.mon.split(",");
             const tues = data.tues.split(",");
             const wed = data.wed.split(",");
             const thur = data.thur.split(",");
             const fri = data.fri.split(",");
             const sat = data.sat.split(",");
             const sun = data.sun.split(",");
 
             const daysnum = [mon, tues, wed, thur, fri, sat, sun];
             const arr = daysnum[indexnum];

             const splicer = (day, val) => {
              settimetwo(val)
               let v = day.indexOf(val);
               day.splice(v, 1);
               setdaysnum[indexnum](day);
               const noclass=document.querySelector(".red2")
               const zclass=document.querySelector(`.selected2${v}`)
               const selecttwo=document.querySelector(".selecttwo")
               const daytwo=document.querySelector(".daytwo")
               const select_trainer=document.querySelector(".select_trainer")
               const selected_trainer=document.querySelector(".selected_trainer")
               selecttwo.classList.add("d-none")
               select_trainer.classList.remove("d-sm-flex")
               select_trainer.classList.add("d-none")
               daytwo.classList.remove("d-none")
               selected_trainer.classList.remove("d-none")
               noclass && noclass.classList.remove("red2")
               zclass.classList.add("red2")
             };
             let newmon = [];
             for (let index = 0; index < arr.length; index++) {
               newmon.push(
                 <button className={`times selected2${index}`}
                   type="button"
                   
                   defaultValue={arr[index]}
                   onClick={(e) => splicer(arr, arr[index])}
                 >{timeConverter(arr[index],zone)}</button>
               );
             }
 
             return newmon;
           }
         }, [])}
 </div>
 <div className="d-none">
 
  
         <label for="email">email:</label>
         <input id="user_email" name="user_email" value={tutoremail}/>
        
         <br />
         <br />
         <label for="mon">mon :</label>
         <input
           id="mon"
           name="mon"
           defaultValue={monday}
           onChange={(e) => setmonday(e.target.value)}
         />
         <br />
         <br />
         <label for="tues">tues :</label>
         <input
           id="tues"
           name="tues"
           defaultValue={tuesday}
           onChange={(e) => settuesday(e.target.value)}
         />
         <br />
         <br />
         <label for="wed">wed :</label>
         <input id="wed" name="wed" defaultValue={wednsday}
           onChange={(e) => setwednsday(e.target.value)} />
         <br />
         <br />
         <label for="thur">thur :</label>
         <input id="thur" name="thur"
           defaultValue={thursday}
           onChange={(e) => setthursday(e.target.value)} />
         <br />
         <br />
         <label for="fri">fri :</label>
         <input id="fri" name="fri"
           defaultValue={friday}
           onChange={(e) => setfriday(e.target.value)} />
         <br />
         <br />
         <label for="sat">sat :</label>
         <input id="sat" name="sat"
           defaultValue={saturday}
           onChange={(e) => setsaturday(e.target.value)} />
         <br />
         <br />
         <label for="sun">sun :</label>
         <input id="sun" name="sun"
           defaultValue={sunday}
           onChange={(e) => setsunday(e.target.value)} />
       
     </div>
     </div>
        </div>

        <div className="items">
          <p> Previous or Current School </p>
        </div>
        <input
            label="school"
            placeholder="Last school you enrolled"
            type="text"
            defaultvalue={school}
            onChange={(e) => setschool(e.target.value)} required
          ></input>

        <div className="full">
          <p>Learning Status</p>
          <select label="Status" placeholder="complete course or not"
          value={status}
          onChange={(e) => setstatus(e.target.value)} required>
            <option value="" disabled selected>
              select your status
            </option>
            <option value="completed">completed</option>
            <option value="Currently_enrolling">Currently Enrolling</option>
          </select>
        </div>
        <div className="full">
          <p>
            Batch or Academic year <span className="note">(optional)</span>
          </p>
          <input placeholder="Enter your batch" value={batch} onChange={(e) => setbatch(e.target.value)} required></input>
        </div>
       
        <div className="items">
          <p>Phone number</p>
          
        
        <div className="sign__right__phone__para">
          
          <select className="selecta"
            value={searchCode}
            onChange={(e) => setSearchCode(e.target.value)}
            required
          >
            <option value="">Country</option>
            {countries.map((item) => {
              return (
                <option key={uuidv4()} value={item.code}>
                  {item.name}
                </option>
              );
            })}
          </select>
          
        
          <input class="phone_code" name="phone_code" placeholder="Code" 
           value={(searchCountry 
            && searchCountry.dial_code) || ""
          }
           type="tel" required 
           />
       
          <input
            class="phone_num"
            name="phone_num"
            type="tel"
            placeholder="Phone Number"
            value={phoneNum}
            onChange={(e) => setphoneNum(e.target.value)} required
          />
        
      </div>
      </div>

        {/* <div className="items">
          <p>Prefered Schedule (time zone is in EST, DC-time)</p>
        </div>
        <div className="items">
          <p> Day 1 </p>
          
            <select name="days"
            value={day1}
            onChange={(e) => setday1(e.target.value)} required>
              <option value="" disabled>select date</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednsday">Wednsday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
         
          <input class='time' label="t1" placeholder="Time" type="time"
          value={time1}
          onChange={(e) => settime1(e.target.value)} required></input>
        </div>
        <div className="items">
          <p> Day 2 </p>
          
            <select name="days"
            value={day2}
            onChange={(e) => setday2(e.target.value)} required>
              <option value="" disabled>select date</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednsday">Wednsday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          
          <input class='time'label="t1" placeholder="Time" type="time"
          value={time2}
          onChange={(e) => settime2(e.target.value)} required></input>
        </div> */}
        <div className="remember">
          <strong>Remember</strong> :- Andmta skills is a company of its own, it
          is not working in a partnership with any other company.
        </div>

        <div>
          <input
            className="apply"
            label="submit"
            type="submit"
            value="SUBMIT"
          ></input>
        </div>
      </form>
    </div>
  );
}

export default PersonalTrainingRequest;

import React from 'react'
import { Table } from 'react-bootstrap';
import ModalContent from "../../../Modal/Modal";
import { useStateValue } from '../../../StateProvider/StateProvider';


function ModalQuestion() {
  const [{ basket, user }, dispatch] = useStateValue();
    
  function formSubmit() {
   
    {
      document.getElementById("linkform").submit();
      alert('Thank You for your contribution Your Answer Will Be Added In Q & A List Soon')
   
    }
    
  }
  const close = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <div className="createMeeting ">
      <ModalContent
        content={
            <div className='bg-white text-dark '>
            <form id="linkform"
            action={process.env.REACT_APP_ADD_QandA}
            method="post" 
            >
            <Table className="container">
                <thead className=""></thead>
                <td colSpan={2} className="text-center text-uppercase fs-4">insert new Question and </td>
                <tbody>
                <tr>
                    <td colSpan={2}><input className='w-100 px-2 d-none' name="email" id="email" placeholder="Type Description" value={user && user.email} required></input></td>
                </tr>
                <tr>
                    <td colSpan={2} ><input className='w-100 px-2 text-wrap overflow-visible' name="link" id="link" placeholder="Insert full Link ( if your answer source is from another website)" ></input></td>
                </tr>
                <tr>
                    <td colSpan={2}><input className='w-100 px-2' name="question" id="question" placeholder="Insert question" required></input></td>
                </tr>
                <tr>
                    <td colSpan={2}><textarea className='w-100 px-2 overflow-visible' name="answer" id="answer" rows="12" placeholder="Insert Answer" required></textarea></td>
                </tr>
                <tr>
                    <td colSpan={2}><input className='w-100 px-2 d-none' name="status" id="status"  value="new" ></input></td>
                </tr>
                <tr>
                  <td className="cancel">
                    {" "}
                    <button value="cancel w-100" onClick={close}>
                      {" "}
                      Cancel
                    </button>
                  </td>

                  <td className="submit">
                    {" "}
                    <input
                    className='px-3 w-100 '
                    type="button"
                      
                      onClick={formSubmit}
                      value="add"
                    ></input>
                  </td>
                </tr>
                </tbody>

            </Table>
                
                
            </form>
            </div>
        }
        
        button_text={"Add New Answer"}
      />
    </div>
  )
}

export default ModalQuestion
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import "firebase/compat/auth"
import "firebase/compat/firestore"
import {getFirestore,collection,getDocs,limit } from 'firebase/firestore'


// import "firebase/compat/firestore"



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
projectId: process.env.REACT_APP_FIREBASE_CONFIG_PEOJECT_ID,
storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGE_SENDER_ID,
appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID}
;
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  initializeApp(firebaseConfig)
    const db = firebaseApp.firestore();
    const auth = firebase.auth();
    // const db = getFirestore();
    const users=collection(db, 'users');
    const hosting_form=collection(db, 'hosting_form');
  
    // const coll=firebaseApp.firestore.collection();
    
    export { auth,db, users,hosting_form};
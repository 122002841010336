import React from 'react'
import "./Footer.css"
import {FaCopyright, FaFacebook, FaLinkedin, FaTelegram, FaWhatsapp, FaYoutube} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {BiCopyright} from 'react-icons/bi'
import { useStateValue } from '../StateProvider/StateProvider'


function Footer() {
  const [{ basket, user }, dispatch] = useStateValue();
  return (
    <div className='footer '>
        <div className='container'>
        <div className='footer__top'> Stay In Touch</div>
        <div className='footer__icons'>
        <a href='https://www.facebook.com/profile.php?id=100088255660027' target="_blank" >< FaFacebook color="white"/></a>
        <a href='https://t.me/contact_andmtaskills' target="_blank" >< FaTelegram color="white"/></a>
        <a href='https://wa.me/message/6FPKHSIQ2QTYM1' target="_blank" >< FaWhatsapp color="white"/></a>
        <a href='https://www.linkedin.com/in/tilahun-feyissa-55b223194' target="_blank" >< FaLinkedin color="white"/></a>
        <a href='https://www.youtube.com/@andmta1' target="_blank" >< FaYoutube color="white"/></a>
        </div>
        <div className='footer__contact'>
            {/* <div>Contact us</div> */}
            <div className='footer__contact__items '>
                <p>Addis ababa, Ethiopia</p>
                <p> +251 946 33 05 05 </p>
                <p> andmtaskills@gmail.com</p>
                <p>  P.O Box 46806</p>
            </div>
        </div>
        {/* <div className='footer__links '>
            
            <Link to="/termsOfServiece" className='footer__link'>Privacy Policy </Link> 
            <Link to="/termsOfServiece" className='footer__link'> Terms of use </Link>
            <Link to="/termsOfServiece" className='footer__link'> About us</Link>
            
    
        </div> */}
        <div className='footer__copy'>
        <div className='footer__copyright'> Copyright 2023 Andmta Skills  <p className='px-2'>< BiCopyright/></p> All rights Reserved </div>
        </div>
        
        </div>
        
    </div>
  )
}

export default Footer
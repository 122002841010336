import React from "react";
import { useState } from "react";
import "./SignUp.css";
import { auth } from "../../../firebase";

// import FormInput from "../../FormInput";
import axios from "axios";
import { BsPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiCoinsLine, RiLockPasswordFill } from "react-icons/ri";
import { getDocs, addDoc, collection, FieldValue } from "firebase/firestore";
import { db } from "../../../firebase";
import CountryData from "./CountryData.json";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const navigate = useNavigate();

  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setEmail] = useState("");
  const [code, setcode]=useState("")
  const [phoneNum, setphoneNum] = useState("");
  const [password, setpassword] = useState("");

  


  const register = (e) => {
    e.preventDefault();

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        if (auth) {
          navigate('/');
        }
      })
      .then((e) => {
        handleSubmit()
      })
      
      .then((e) => {
        alert("your account sucessfuly registered");
      })
      .catch((error) => 
      
      // alert("Email address is already in use or parameters incorrectly formatted"))
      alert(error.message))
       };

  const [countries, setCountries] = useState(CountryData);
  

  const [searchCode, setSearchCode] = useState();
  

  const searchCountry = countries.find((obj) => {
    if (obj.code === searchCode) {
      return true;
    }
    return false;
  })
 
   
    const handleSubmit = (e) => {
      addDoc(collection(db, "users"), {
        fname: fname,
        lname: lname,
        email: email,
        phoneNum: phoneNum,
        code: searchCountry.dial_code,
      })
        .catch((err) => {
          // console.log(err);
        });
    };
    

    const [isActive, setIsActive] = useState(false);
    const addclass=()=>{
      setIsActive(current => !current);

    }
  return (
    <div className={isActive ? 'display' : ''}>
      
    <form onSubmit={ register} >
      <div className="sign__right__title">Create New Account</div>
      <div className="sign__right__name in pro">
        <BsPersonFill />
        <input
          name="fname"
          type="text"
          placeholder="First Name"
          value={fname}
          onChange={(e) => setfname(e.target.value)} required
        />
        <input
          name="lname"
          type="text"
          placeholder="Last Name"
          value={lname}
          onChange={(e) => setlname(e.target.value)} required
        />
      </div>
      <div className="sign__right__email in">
        <MdEmail />
        <input
          name="email"
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)} required
        />
      </div>
      <div className="sign__right__phone in pro">
        <BsFillTelephoneFill />{" "}
        <div className="sign__right__phone__para">
          
            <select className="selecta"
              value={searchCode}
              onChange={(e) => setSearchCode(e.target.value)}
              required
            >
              <option value="">Country</option>
              {countries.map((item) => {
                return (
                  <option key={uuidv4()} value={item.code}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            
          
            <input name="phone_code" placeholder="Code" 
             value={(searchCountry 
              && searchCountry.dial_code) || ""
            }
             type="tel" required 
             />
         
            <input
              name="phone_num"
              type="tel"
              placeholder="Phone Number"
              value={phoneNum}
              onChange={(e) => setphoneNum(e.target.value)} required
            />
          
        </div>
      </div>
      <div className="sign__right__password in">
        <RiLockPasswordFill />{" "}
        <input
          name=""
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setpassword(e.target.value)} required
        />
      </div>

      <div className="sign__right__sign">
        <button className="login__signupButton"
          type="submit"
        >SIGN UP
        </button>
        
      </div>
      

      
    </form>
    
  </div>
  
  );
}

export default SignUp;

import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./MeetingReport.css";

function StudentMeetingReport({
  email,
  start_time,
  Meeting_Link,
  today_plan,
  meetingdate,
  end_time,
  lessons_covered,
  next_day_plan,
  comment,
  tutor_comment,
  report_status,
}) {
  // const [today_plan, settoday_plan] = useState("zzz");
  const [ed_time, seted_time] = useState("");
  const [meeting_status, setmeeting_status] = useState("");
  const [reports, setreports] = useState("");
  // console.log(meeting_status)

  useEffect(() => {
    fetch(process.env.REACT_APP_DAILYREPORT_FETCH)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setreports(data.dailyreport);
        } else {
          // console.log(err)
        }
      });
  }, []);

 
  const ed_timer = (e) => {
    e.preventDefault();
    var today = new Date();
    var time = today.getTime();
    // console.log(today)
    // var time = today.getHours() + ":" + today.getMinutes()
    alert("you are about to end this meeting");
    seted_time(time);
  
  };
 
  const tConvert = (start_time) => {
    const tonum = Number(start_time);
    const date = new Date(tonum);
    var time = date.getHours() + ":" + date.getMinutes();
    time = time
      .toString()
      .match(/^(?:(?:([01]?\d|2[0-3])(:))?([0-5]?\d))$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[0] = +time[0]<10?`0${+time[0]}`:time[0]
      time[2] = +time[2]<10?`0${+time[2]}`:time[2]
    }
    return time.join(""); // return adjusted time or original string
  };

const classer=()=>{
    let statusdiv = document.querySelector(".statusdiv");
    if(report_status==="approved")
{statusdiv.classList.add("approved")}
   else if(report_status==="declined")
{statusdiv.classList.add("declined")}

}

  const submiter = (e) => {
    e.preventDefault()
    
    let form = document.querySelector(".updateform");
    form.action = process.env.REACT_APP_STUDENT_UPDATE_REPORT;
    form.method = "post";
    form.submit();
    console.log(start_time)
    console.log(comment)
   
  };

 
  return (
    <div className="meeting_report">
      <form
        className="updateform" 
        action={process.env.REACT_APP_STUDENT_UPDATE_REPORT}
        method="post"
      >
        <Table className="">
          <thead>
            <tr>
              <th>
                {email}
                <input
                    readOnly
                    className="d-none "
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                  ></input>
                <input readOnly
                  type="text"
                  name="meetingdate"
                  id="meetingdate"
                  value={meetingdate}
                ></input>
              </th>
              <th className="d-flex top flex-column flex-md-row" colSpan="5">
                <span className="d-flex">
                  <p className="px-2">Starting Time :-</p>
                  {tConvert(start_time)}{" "}
                  <input
                    className=" d-none w-25 text-center "
                    name="start_time"
                    id="start_time"
                    defaultValue={start_time}
                  ></input>
                </span>
                <span className="d-flex ">
                  <p className="px-2">
                    End time :- {end_time ? tConvert(end_time) : ""}
                  </p>
                  <input
                    className="d-none w-25 text-center"
                    name="end_time"
                    id="end_time"
                    defaultValue={end_time ? end_time : ed_time}
                  ></input>
                </span>
                {report_status?<span className="statusdiv text-uppercase text-dark px-2 text-left ">
                {report_status}
                </span>:"pending"}
                

                <span className="statusdiv d-none text-uppercase text-dark px-3 text-center">
                {report_status}
                </span>
                <input
                    className="d-none w-25 text-center "
                    name="report_status"
                    id="report_status"
                    defaultValue={meeting_status}

                  ></input>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="w-100 d-flex flex-column flex-md-row ">
              <div className="half">
              <td>Today Plan :-</td>
              <td >
                {today_plan}
                <textarea
                  id="today_plan"
                  name="today_plan"
                  className="d-none"
                  defaultValue={today_plan}
                ></textarea>
              </td>
              </div>
              <div className="half">

              <td>Next Day Plan :- </td>
              <td>
                {next_day_plan}
                <textarea
                  className="d-none"
                  id="next_day_plan"
                  name="next_day_plan"
                  defaultValue={next_day_plan}
                  
                ></textarea>
              </td>
              </div>
            </tr>

            <tr className="w-100 d-flex flex-column flex-md-row ">
              <div className="half">
              <td>Tutor comment:-</td>
              <td>
                {tutor_comment}
                <textarea
                  className="d-none"
                  id="tutor_comment"
                  name="tutor_comment"
                  defaultValue={tutor_comment}
                ></textarea>
              </td>
              </div>
              <div className="d-flex student_comment flex-column">

              <td>Student Comment</td>
              <td colSpan="2">
                <textarea
                className="w-100 student_comment_input"
                  id="comment"
                  name="comment"
                  defaultValue={comment}
                  rows="3"
                ></textarea>
                
                {report_status==="approved"?"":
                <button class="submit_btn" onClick={e=>(submiter)}>
                  Submit comment
                </button>}
              </td>
              </div>
            </tr>
            <tr className="d-flex justify-content-between">
              <td>Lessons Covered</td>
              <td colSpan="d-flex">
                {lessons_covered}
                <textarea
                  className="d-none"
                  id="lessons_covered"
                  name="lessons_covered"
                  defaultValue={lessons_covered}
                ></textarea>
              </td>

              {/* <td className="btn" colSpan="3">
                <button onClick={ed_timer}>Stop Timer</button>
              </td> */}
              <td></td>
              <td className="btn d-flex justify-content-center" colSpan="3">
                <input
                  className="w-100 d-none "
                  defaultValue={Meeting_Link}
                  type="text"
                  id="Meeting_Link"
                  name="Meeting_Link"
                ></input>
                {end_time?"":<div>
                {{Meeting_Link}?<a className="join_meeting pt-2" href={Meeting_Link} target="_blank">
                  Join meeting
                </a>:""}</div>}
                {/* {buttonlist} */}
                {end_time?<div>
                {report_status==="pending" || report_status===""?<><button className="mx-2 bg-sucess" onClick={(e)=>{setmeeting_status("approved")}}>
                  Approve
                </button>
                <button className="mx-2 bg-sucess" onClick={(e)=>setmeeting_status("declined")}>
                  decline
                </button></>:""}
                {report_status==="declined"?<button className="mx-2 bg-sucess" onClick={(e)=>{setmeeting_status("approved")}
                }>
                  Approve
                </button>:""}</div>:""}


              
                
              </td>
            </tr>
          </tbody>
        </Table>
      </form>
    </div>
  );
}

export default StudentMeetingReport;

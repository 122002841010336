import React, { useEffect, useState, useRef } from "react";
import { db } from "../../firebase";
import Project from "../GroupProjects/Project";
import "./Admin.css";
import "../GroupProjects/HostingForm/HostingForm.css";
import { Button } from "bootstrap";
import {
  addDoc,
  collection,
  query,
  getDocs,
  where,
  getDocslimit,
  orderBy,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import AdminMenu from "./AdminMenu";
import { useStateValue } from "../StateProvider/StateProvider";

function Admin() {
  //states for hosting request
  const [{ user }] = useStateValue([]);
  const [Project_title2, setProject_title2] = useState("andmta");
  const [Project_title, setProject_title] = useState("andmta");
  const [Project_link, setProject_link] = useState("");
  const [github_link, setgithub_link] = useState("");
  const [st_date, setst_date] = useState("");
  const [st_timezone, setst_timezone] = useState("");
  const [day1, setday1] = useState("");
  const [time1, settime1] = useState("");
  const [day2, setday2] = useState("");
  const [time2, settime2] = useState("");
  const [project_delay, setproject_delay] = useState("");
  const [Project_description, setProject_description] = useState("");
  const [Project_des, setProject_des] = useState("");
  const [files_associated, setfiles_associated] = useState("");

  // --------------------------------------------------------------------
  // for updating with new data
  const [Project_title3, setProject_title3] = useState("");
  const [Project_link2, setProject_link2] = useState("");
  const [github_link2, setgithub_link2] = useState("");
  const [st_date2, setst_date2] = useState("");
  const [st_timezone2, setst_timezone2] = useState("");
  const [day12, setday12] = useState("");
  const [time12, settime12] = useState("");
  const [day22, setday22] = useState("");
  const [time22, settime22] = useState("");
  const [project_delay2, setproject_delay2] = useState("");
  const [Project_description2, setProject_description2] = useState("");
  const [Project_des2, setProject_des2] = useState("");
  const [files_associated2, setfiles_associated2] = useState("");

  // for updating with new data
  // --------------------------------------------------------------------

  // ------------------------------------------------------------
  // ------------------mapping and settin id--------------------
  const [reqe, setreqe] = useState([]);
  const [currentid, setcurrentid] = useState("");
  const firstRef = useRef(null);
  const [waiting, setwaiting] = useState([]);
  const [proid, setproid] = useState("");

  // ------------------mapping and settin id--------------------
  // ------------------------------------------------------------

  // --------------------------------------------------------
  // -------------initial fetching---------------------------
  // set on reqe

  useEffect(() => {
    db.collection("hosting_form")
      .orderBy("st_date")
      .onSnapshot((snapshot) =>
        setreqe(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  }, []);
  console.log(reqe);

  // ---------------initial fetching---------------------------
  // ------------------------------------------------------------

  // ----------------------------------------------------------------
  // ------------for assign id and title when selected--------------

  // --------------title assign starter-----------------------------
  const changer = () => {
    titlassign();
  };

  // ---------project title assigner and id assign starter-------------
  const titlassign = () => {
    console.log(firstRef.current.value);
    setProject_title2(firstRef.current.value);
    idassign();
  };

  // -------------id assingner---------------------------------
  const idassign = () => {
    reqe?.map((item) => {
      let current_Project_title = item.data.Project_title;
      let prolink = item.data.Project_link;
      let gitlink = item.data.github_link;
      let id = item.id;

      if (item.data.Project_title == firstRef.current.value) {
        setcurrentid(id);
        setProject_title3(item.data.Project_title);
        setProject_link2(prolink);
        setgithub_link2(gitlink);
        setst_date(item.data.st_date);
        setday1(item.data.day1);
        settime1(item.data.time1);
        setday2(item.data.day2);
        settime2(item.data.time2);
        setproject_delay(item.data.project_delay);
        setProject_description(item.data.Project_description);
      }
    });
  };

  // -----------------------firestore group project data updator function--------------
  const fireupdate = () => {
    db.collection("hosting_form")
      .doc(currentid)
      .update({
        Project_title: Project_title3,
        Project_link: Project_link2,
        github_link: github_link2,
        st_date: st_date,
        day1: day1,
        time1: time1,
        day2: day2,
        time2: time2,
        project_delay: project_delay,
        Project_description: Project_description,
        status: "confirmed",
      })
      .then(() => {
        alert("group project data modified");
        updateProject();
        window.location.reload();
      });
  };
  // ============================hosting form data end=======================

  // =========================project data start===========================

  // ------------------------------------------------------------------------------
  // --------------add project data to project waiting list or the first time-----------
  const createProject = () => {
    reqe?.map((item) => {
      if (item.data.status === "pending") {
        const title = Project_title3;
        const docRef = doc(db, "projects", "waiting", title, "data");
        const colRef = collection(docRef, "project_data");

        addDoc(colRef, {
          Project_title: Project_title3,
          Project_ID: currentid,
          Project_link: Project_link2,
          github_link: github_link2, 
          st_date: st_date,
          day1: day1,
          time1: time1,
          day2: day2,
          time2: time2,
          project_delay: project_delay,
          Project_description: Project_description,
        });
      }
    });
  };

  // --------------add project to project waiting list or the first time-----------
  // ------------------------------------------------------------------------------

  // ----------------firstore data update starter----------------
  // ----------------project data creater ----------------
  // ----------------project data update starter ----------------
  const updator = (e) => {
    e.preventDefault();
    reqe?.map((item) => {
      let current_Project_title = item.data.Project_title;
      let id = item.id;
      if (current_Project_title == Project_title2) {
        fireupdate();
        createProject();
        updateProject();
      }
    });
  };

  // ----------------project data id assigner-----------------------
  // map project id and prepare for update the project
  useEffect(() => {
    waiting?.map((item) => {
      setproid(item.id);
    });
  });
  // ----------------project data id assigner end-----------------------
  // ========================================================================================
  // ----------------project data state initialize start-----------------------
  // set waiting state  with selected project id
  const project_state_init = async () => {
    db.collection("projects")
      .doc("waiting")
      .collection(firstRef.current.value)
      .doc("data")
      .collection("project_data")
      .onSnapshot((snapshot) =>
        setwaiting(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            // data: doc.data(),
          }))
        )
      );
  };
  // ----------------project data state initialize end-----------------------
  // =======================================================================================
  // ------------------------------------------------------------------------------
  // --------------update project data to project waiting list start-----------
  const updateProject = () => {
    db.collection("projects")
      .doc("waiting")
      .collection(Project_title2)
      .doc("data")
      .collection("project_data")
      .doc(proid)
      .update({
        Project_title: Project_title3,
        Project_link: Project_link2,
        github_link: github_link2,
        st_date: st_date,
        day1: day1,
        time1: time1,
        day2: day2,
        time2: time2,
        project_delay: project_delay,
        Project_description: Project_description,
      });
  };

  // --------------update project data to project waiting list end-----------
  // ------------------------------------------------------------------------------
  // ===================================================================================
  if (user?.email === "totilahunfeyissa@gmail.com") {
    return (
      <div className="admin">
        <div className="admin_menu">
          <AdminMenu />
        </div>
        <p>Pending Projects</p>

        {reqe &&
          reqe.map((reqe) => {
            if (reqe.data.status === "pending") {
              return (
                <div key={reqe.id}>
                  <div>
                    <input type="text" Value={reqe.data.Project_title}></input>
                  </div>
                </div>
              );
            }
          })}
        <p>Project titles waiting list</p>
        {reqe &&
          reqe.map((reqe) => {
            return (
              <div key={reqe.id}>
                <div>
                  <input type="text" Value={reqe.data.Project_title}></input>
                </div>
              </div>
            );
          })}
        <div>
          <p>waiting projects</p>
          <form>
            <input
              name="Pro_title2"
              id="Pro_title2"
              type="text"
              ref={firstRef}
 
              onChange={(e) => {
                changer();
                project_state_init();
              }}

            ></input>
          </form>

          {reqe &&
            reqe.map((reqe) => {
              if (reqe.data.Project_title === Project_title2) {


                return (
                  <div>
                    <div className="hostingform container">
                      <form onSubmit={updator}>
                        <div className="full">
                          <p>{Project_title2}</p>
                          <p>Project Title</p>
                          <input
                            label="Project_title"
                            type="text"
                            Value={reqe.data.Project_title}

                            onChange={(e) => {
                              setProject_title3(e.target.value);
                              
                            }}
                            
                          ></input>
                        </div>
                        <div className="full">
                          <p>
                            Project Link{" "}
                            <span className="note">(optional)</span>
                          </p>
                          <input
                            label="Project_link"
                            placeholder="If it deployed previously or if the project is clone of live site"
                            Value={reqe.data.Project_link}
                            // value={Project_link}
                            onChange={(e) => setProject_link2(e.target.value)}
                          ></input>
                        </div>
                        <div className="full">
                          <p>
                            Github Link <span className="note">(optional)</span>
                          </p>
                          <input
                            label="github_link"
                            placeholder="If the project previously pushed to Git repository"
                            value={github_link}
                            onChange={(e) => setgithub_link2(e.target.value)}
                          ></input>
                        </div>
                        <div className="items">
                          <p>Starting Date</p>
                          <input
                            label="st_date"
                            placeholder="Date"
                            type="date"
                            value={st_date}
                            onChange={(e) => setst_date(e.target.value)}
                          ></input>
    
                        </div>
                        <div className="items">
                          <p>Weekly Schedule Day 1</p>
                          <div className="d-flex">
  

                            <select
                              name="days"
                              value={day1}
                              onChange={(e) => setday1(e.target.value)}
                            >
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednsday">Wednsday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                              <option value="Sunday">Sunday</option>
                            </select>

                            <input
                              label="time1"
                              placeholder="Time"
                              type="time"
                              value={time1}
                              onChange={(e) => settime1(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div className="items">
                          <p>Weekly Schedule Day 2 </p>
                          <div className="d-flex">
                            {/* <input label="day2"  type="date"
          value={day2}
          onChange={(e) => setday2(e.target.value)} ></input> */}

                            <select
                              name="days"
                              value={day2}
                              onChange={(e) => setday2(e.target.value)}
                            >
                              <option value="">None</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednsday">Wednsday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                              <option value="Sunday">Sunday</option>
                            </select>

                            <input
                              label="time2"
                              placeholder="Time"
                              type="time"
                              value={time2}
                              onChange={(e) => settime2(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div className="items">
                          <p>
                            Estimated Project Delay{" "}
                            <span className="note">(optional)</span>
                          </p>
                          <input
                            label="project_delay"
                            placeholder="Weeks"
                            type="number"
                            value={project_delay}
                            onChange={(e) => setproject_delay(e.target.value)}
                          ></input>
                        </div>
                        <div className="full">
                          <p>Project Description</p>
                          <textarea
                            label="Project_description"
                            placeholder="project description"
                            rows="5"
                            defaultValue={Project_description}
                            onChange={(e) =>
                              setProject_description(e.target.value)
                            }
                          ></textarea>
                        </div>
                        {/*                       
                      <div>

                        <p>
                          Wed page design Images{" "}
                          <span className="note">(optional)</span>
                        </p>
                        <input
                          label="Project_des"
                          placeholder="Drag and drop files OR"
                          type="file"
                          value={reqe.data.Project_des}
                          onChange={(e) => setProject_des(e.target.value)}
                        ></input>
                        <p>
                          Additional files related to this project{" "}
                          <span className="note">(optional)</span>
                        </p>
                        <input
                          label="files_associated"
                          placeholder="files associated to this project"
                          type="file"
                          value={reqe.data.files_associated}
                          onChange={(e) => setfiles_associated(e.target.value)}
                        ></input>
                      </div> */}

                        <button className="apply" type="submit">
                          Confirm
                        </button>
                      </form>
                    </div>
                  </div>
                );
              } else {
                // alert ("no data")
              }
            })}
          {/* </form> */}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>SORRY!! YOU DONT HAVE A PERMISSION TO ACCESS THIS PAGE</h1>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Admin;

import React, { useEffect } from 'react'
import './ResourcesMenu.css'

function ResourcesMenu() {

  
  // let documents_btn=document.querySelector('.documents_btn')
  // let blogs_btn=document.querySelector('.blogs_btn-')
  // let usefull_btn=document.querySelector('.usefull_btn')
  let documents=document.querySelector('.documents')
  let blogs=document.querySelector('.blogs')
  let usefull=document.querySelector('.usefull')
  

const filterDocuments=(e)=>{
  let documents=document.querySelector('.documents')
  let blogs=document.querySelector('.blogs')
  let usefull=document.querySelector('.usefull')
  let qanda=document.querySelector('.qanda')
  let templates=document.querySelector('.templates')
  templates && templates.classList.add('d-none')
  qanda && qanda.classList.add('d-none')
   documents && documents.classList.remove('d-none')
  blogs && blogs.classList.add('d-none')
  usefull && usefull.classList.add('d-none')
  window.scrollTo(0, 0);

}
const filterBlogs=(e)=>{
  let documents=document.querySelector('.documents')
  let blogs=document.querySelector('.blogs')
  let usefull=document.querySelector('.usefull')
  let qanda=document.querySelector('.qanda')
  let templates=document.querySelector('.templates')
  templates && templates.classList.add('d-none')
  qanda && qanda.classList.add('d-none')
  documents && documents.classList.add('d-none')
  blogs && blogs.classList.remove('d-none')
  usefull && usefull.classList.add('d-none')
  window.scrollTo(0, 0);
}
const filterUsefull=(e)=>{
  let documents=document.querySelector('.documents')
  let blogs=document.querySelector('.blogs')
  let usefull=document.querySelector('.usefull')
  let qanda=document.querySelector('.qanda')
  let templates=document.querySelector('.templates')
  templates && templates.classList.add('d-none')
  qanda && qanda.classList.add('d-none')
  documents && documents.classList.add('d-none')
  blogs && blogs.classList.add('d-none')
  usefull && usefull.classList.remove('d-none')
  window.scrollTo(0, 0);
}
const filterQandA=(e)=>{
  let documents=document.querySelector('.documents')
  let blogs=document.querySelector('.blogs')
  let usefull=document.querySelector('.usefull')
  let qanda=document.querySelector('.qanda')
  let templates=document.querySelector('.templates')
  templates && templates.classList.add('d-none')
  documents && documents.classList.add('d-none')
  blogs && blogs.classList.add('d-none')
  usefull && usefull.classList.add('d-none')
  qanda && qanda.classList.remove('d-none')
  window.scrollTo(0, 0);
}
const filterTemplates=(e)=>{
  let documents=document.querySelector('.documents')
  let blogs=document.querySelector('.blogs')
  let usefull=document.querySelector('.usefull')
  let qanda=document.querySelector('.qanda')
  let templates=document.querySelector('.templates')
  templates && templates.classList.remove('d-none')
  documents && documents.classList.add('d-none')
  blogs && blogs.classList.add('d-none')
  usefull && usefull.classList.add('d-none')
  qanda && qanda.classList.add('d-none')
  window.scrollTo(0, 0);
}

  return (
    <div className='resources_menu  '>
    <div className='resources_menu_items  d-flex flex-md-column text-uppercase justify-content-center'>
      <button className='resources_menu_item documents_btn' onClick={e=>filterDocuments()}>Documents</button>
      <button className='resources_menu_item usefull_btn' onClick={e=>filterUsefull()}>usefull links</button>
      {/* <button className='resources_menu_item blogs_btn' onClick={e=>filterBlogs()}>blogs</button> */}
      <button className='resources_menu_item ' onClick={e=>filterQandA()}>Best Answers</button>
      <button className='resources_menu_item' onClick={e=>filterTemplates()}>Templates</button>
      
      
    </div>
    </div>
    
  )
}

export default ResourcesMenu
import React from 'react'
import { Table } from 'react-bootstrap';
import ModalContent from "../../../Modal/Modal";
import { useStateValue } from '../../../StateProvider/StateProvider';


function ModalUsefullLink() {
  const [{ basket, user }, dispatch] = useStateValue();
    
  function formSubmit() {
   
    {
      document.getElementById("linkform").submit();
      alert('Thank You for your contribution Your Link Will Be Added In Usefull Links List Soon')
    //   window.location.reload();
    }
    
  }
  const close = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <div className="createMeeting ">
      <ModalContent
        content={
            <div className='bg-white text-dark '>
            <form id="linkform"
            action={process.env.REACT_APP_ADD_LINK}
            method="post" 
            >
            <Table className="container">
                <thead className=""></thead>
                <td colSpan={2} className="text-center text-uppercase fs-4">insert new url and description</td>
                <tbody>
                <tr>
                    <td colSpan={2}><input className='w-100 px-2 d-none' name="email" id="email" placeholder="Type Description" value={user && user.email} required></input></td>
                </tr>
                <tr>
                    <td colSpan={2}><input className='w-100 px-2' name="link" id="link" placeholder="Insert Website full Link (including https://)" required></input></td>
                </tr>
                <tr>
                    <td colSpan={2}><input className='w-100 px-2' name="description" id="description" placeholder="Type Description" required></input></td>
                </tr>
                <tr>
                    <td colSpan={2}><input className='w-100 px-2 d-none' name="status" id="status"  value="pending" ></input></td>
                </tr>
                <tr>
                  <td className="cancel">
                    {" "}
                    <button value="cancel w-100" onClick={close}>
                      {" "}
                      Cancel
                    </button>
                  </td>

                  <td className="submit">
                    {" "}
                    <input
                    className='px-3 w-100 '
                    type="button"
                      
                      onClick={formSubmit}
                      value="add"
                    ></input>
                  </td>
                </tr>
                </tbody>

            </Table>
                
                
            </form>
            </div>
        }
        
        button_text={"Add New Link"}
      />
    </div>
  )
}

export default ModalUsefullLink
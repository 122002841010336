import React from 'react'
import './Resources.css'
import Blogs from './ResourcesMenu/Blogs/Blogs'
import Documents from './ResourcesMenu/Documents/Documents'
import QandA from './ResourcesMenu/QandA/QandA'
import ResourcesMenu from './ResourcesMenu/ResourcesMenu'
import Templates from './ResourcesMenu/Templates/Templates'
import UsefullLInks from './ResourcesMenu/UsefullLInks/UsefullLInks'

function Resources() {

// let g=document.querySelector('.documents')
// g && g.classList.add("d-none")

  return (
    <div className='resources row g-0'>
        
        <ResourcesMenu />
        
        <div className='resources_menu col-md-2 g-0'>
        
        </div>
        
        <div className='resources__content g-0 col-md-10'>
        <Documents/>
        <UsefullLInks />
        {/* <Blogs/> */}
        <QandA/>
        <Templates/>
        
        </div>
    </div>
  )
}

export default Resources
import React, { useEffect, useState } from "react";
import "./Group.css";
import Header from "../Header/Header";
import groupWork from "../../resources/images/group_work.jpg";
import Project from "./Project";
import BeHost from "./BeHost";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { query, orderBy, limit } from "firebase/firestore";
import { useStateValue } from "../StateProvider/StateProvider";
import SignInFirstModal from "../Sign/SIgnInFirstModal/SignInFirstModal";

function Group() {
  const [prodata, setprodata] = useState("");
  const [userdata, setuserdata] = useState("");
  const [{ basket, user }, dispatch] = useStateValue();
  useEffect(() => {
    db.collection("hosting_form")
      .orderBy("st_date")

      .onSnapshot((snapshot) =>
        setprodata(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  }, []);
  useEffect(() => {
    db.collection("users").onSnapshot((snapshot) =>
      setuserdata(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      )
    );
  }, []);
  const see = () => {};
  return (
    <div className="group container">
      <div className="group__behost">
        {!user ? (
          <SignInFirstModal button_text={<BeHost />} />
        ) : (
          <Link to="/hostingform" className="d-flex justify-content-center">
            <BeHost />
          </Link>
        )}
      </div>
      <br />
      <div className="group__aim">
        <div className="group__aim__title">This Program Is</div>
        <div className="group__aim__detail">
          <div className="group__aim__detail__left">
            <p>For Who</p>
            <p>
              Complete the full stack web development course <br />
              Complete some web development-related course <br />
              Currently enrolling in technology field course
            </p>
          </div>
          <div className="group__aim__detail__right">
            <p>Want To</p>
            <p>
              Improve their coding skill & team work habit <br />
              Explore new methods and technologies <br />
              Share their knowledge and experience
            </p>
          </div>
        </div>
        <div className="group__aim__summary">
          <p>
            Start today, improve your skill and portfolio, stay
            <strong> one step ahead</strong> on your coding journey by <br />
            <p className="group__aim__summary__btn justify-content-center align-items-center">
            {!user ? (
                <SignInFirstModal
                  className="aim_link "
                  button_text={<p className="aim_link ">Joining in upcoming project</p>}
                />
              ) : (
                <Link to="/ConfirmedProjects" className="aim_link ">
                Joining in upcoming project
              </Link>
              )}
              
              
              or by{" "}
              {!user ? (
                <SignInFirstModal
                  className="aim_link "
                  button_text={<p className="aim_link ">Being Host</p>}
                />
              ) : (
                <Link to="/hostingform" className="aim_link ">
                  Being Host
                </Link>
              )}
              
            </p>
            to any project in your mind
          </p>
        </div>
      </div>
      <div className="imported ">
        <Link to="/ConfirmedProjects" className="imported__links">
          <div className="group__project">
            {prodata &&
              prodata.map((prodata) => {
                const currentDate = new Date();
                const startingDate = new Date(prodata.data.st_date);
                const cdate = currentDate.getTime();
                const sdate = startingDate.getTime();
                if (prodata.data.status === "confirmed") {
                  if (sdate >= cdate) {
                    return (
                      <div key={prodata.id} className="group__project__item">
                        <Project
                          Project_title={prodata.data.Project_title}
                          st_date={prodata.data.st_date}
                          schedule={prodata.data.day1}
                          fname={prodata.data.fname}
                          lname={prodata.data.lname}
                          day1={prodata.data.day1}
                          time1={prodata.data.time1}
                          day2={prodata.data.day2}
                          time2={prodata.data.time2}
                          button={"See Details"}
                        />
                      </div>
                    );
                  }
                }
              })}
          </div>
        </Link>
      </div>

      {/* <div className="group__previous">Previous Projects</div> */}

      <div className="group__top">
        <div className="group__top__image">
          <img className="group__top__image" src={groupWork}></img>
        </div>
        <div className="group__top__text">
          {/* <ul>
            <li>
              Group work is an effective way of learning and cooperation with
              others, and the aim is to learn through group cooperation and
              encourage all individuals to participate in the specific task.
            </li>
            <li>
              {" "}
              Group work helps to improve learning efficiency. That would refer
              to every member of the group actively participating in the
              learning process and activities, each member should have great
              enthusiasm, and learn from tasks shared by everyone.
            </li>
            <li>
              Also, it is encouraged that each member should brainstorm, and
              express their point of view.
            </li>
          </ul> */}
          <Link to="/hostingform" className="group__top__text__button ">
            Click To Host A Project
          </Link>
          {/* <button className="group__top__text__button">Click To Host A Project </button> */}
        </div>
      </div>
    </div>
  );
}

export default Group;

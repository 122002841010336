import React from 'react'
import './BeHost.css'
function BeHost() {
  return (
    <div className='behost'>
    <div className='behosttop'>
        <div className='behosttop__left'>
            <p className='behosttop__left__head'>
            Think Any Project
            </p>
            <p className='behosttop__left__text'>
            That can add at least one new thing 
to your previous experience
            </p>
        </div>
        <div className='behosttop__middle'>
        <p className='behosttop__middle__head2'>Click to </p>
            <p className='behosttop__middle__head'>Be Host</p>
            
            
            
            <p className='behosttop__middle__text'>Lead the group and practice responsibility</p>
        </div>
        <div className='behosttop__right'>
            <p className='behosttop__right__head'>
            Do it With Group
            </p>
            <p className='behosttop__right__text'>
            Share experience and see what 
coding in group looks like
            </p>
        </div>
        </div>
        <div className='behost__bottom'>
        Together, We Will Do Better
        </div>
    </div>
  )
}

export default BeHost
import React, { useEffect, useState } from "react";
import SignInFirstModal from "../../../Sign/SIgnInFirstModal/SignInFirstModal";
import { StateProvider, useStateValue } from "../../../StateProvider/StateProvider";
import ModalUsefullLink from "./ModalUsefullLink";
import UsefillSingle from "./UsefillSingle";
import "./UsefullLInks.css";

function UsefullLInks() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [links,setlinks]=useState([])
  function myFunction() {
    var input, filter, ul, li, a, i, txtValue, data;
    input = document.getElementById("myInput");

    let list = document.querySelector(".usefull_list");
    let single = list && list.getElementsByClassName("single_link");

    filter = input.value.toUpperCase();

    for (i = 0; i < single.length; i++) {
      txtValue = single[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        single[i].style.display = "";
      } else {
        single[i].style.display = "none";
      }
    }
  }
  useEffect(() => {
    fetch(process.env.REACT_APP_GET_LINK)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          
          setlinks(data);
        } else {
          
        }
      });
  }, []);

  return (
    <div className="usefull container">
      <div className="d-flex justify-content-end">
      <input
        className="usefull_search "
        type="text"
        id="myInput"
        onChange={(e) => myFunction()}
        placeholder="Search . . ."
        title="Type in a name"
      ></input></div>
      <div className="usefull_list">
        <div className="d-flex justify-content-start">
      {!user?<SignInFirstModal
        button_text={<button className="bg-success text-white rounded px-3 py-2">Add New Link</button>}/>:<ModalUsefullLink />}
        </div>
        
        {links && links.map((data)=>{
          if(data.status==="active"){
          return <UsefillSingle
          image={data.icon}
          title={data.title}
          description={data.description}
          link={data.link}
          website={data.website}
          />}
        })}

      </div>
    </div>
  );
}

export default UsefullLInks;

import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { db, auth } from "../../firebase";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useStateValue } from "../StateProvider/StateProvider";
// import { db,auth } from '../../firebase';
// import Profile from './Profile';
import "./Profile.css";

function Profile() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [currentuser, setcurrentuser] = useState("");
  const [userdata, setuserdata] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setcurrentuser(email);
      } else {
        setcurrentuser("Sign IN");
      }
    });
  });

  useEffect(() => {
    db.collection("users").onSnapshot((snapshot) =>
      setuserdata(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      )
    );
  }, []);
  useEffect(() => {
    {
      userdata &&
        userdata.map((userdata) => {
          if (currentuser === userdata.data.email) {
            setfname(userdata.data.fname);
            setlname(userdata.data.lname);
          }
        });
    }
  });
  const signout = (e) => {
      signOut(auth)
      .then(() => {
        navigate("/");
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const displayer=()=>{
    let content=document.querySelector('.profile-content')
    let profile=document.querySelector('.profile')
    if(user){content.classList.add("d-block")}
    

  }
  const displayeroff=()=>{
    let content=document.querySelector('.profile-content')
    let profile=document.querySelector('.profile')
    content.classList.remove("d-block")
    

  }
  return (
    <div className="profile" onMouseOver={displayer} onMouseOut={displayeroff}>
      <button className="profile__btn">
        <div className="profile_icon">
          <BsFillPersonFill />
          <div className="profile__name">
            <div>
              
              <div>{!user ? "sign in" : <div>My Profile</div>}</div>
            </div>
          </div>
        </div>
      </button>
      <div className="profile-content">
        <div className="profile-content__user" >
        <div className="profile-content__icon"><BsFillPersonFill /></div>
          <div className="fw-normal text-capitalize profile-content__name">{fname} {lname}</div>
          <div>Dash Board</div>
          
        </div>
        {/* <div className="profile-content__dashboard">
        <Link to='/user'>Dash Board</Link>
        </div> */}
        {/* <button onClick={signout}>Sign Out</button> */}
        <div className="profile-content__signout">
          <div>{!user ? "" : <button onClick={signout}>Sign Out</button>}</div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

import React from 'react'
import './NewTraineeWait.css'
import {FaCopyright, FaFacebook, FaLinkedin, FaTelegram, FaWhatsapp, FaYoutube} from 'react-icons/fa'

function NewTraineeWait() {
    const read=()=>{
        const terms=document.querySelector(".new__terms__of__serviece")
        console.log(terms.classList)
        terms && terms.classList.toggle("d-none")
        
    }
    
  return (
    <div className='new_trainee_wait '>
        <div className='container new_trainee_wait__content'> 
        <div className='new_trainee_wait_thankyou text-center py-3'>Thank you for subscribing to our personal tutoring program</div>
        <div className='new_trainee_wait__read py-3'>Before further steps read <button onClick={read}>program details and terms of service</button></div>
        <div className='new__terms__of__serviece d-none'>
            <h2 className='text-center py-3'>Terms of service</h2>
            <div className=''>
              <ul>
              <p className='term_title'>Tutor </p>
              <li> A Tutor is a person responsible for helping students learn and understand new concepts and complete assignments.Both the student and the tutor plan on the topics covered on this program </li>
              <p className='term_title'>Quality Control person </p>
              <li>A quality control person is a person who assigned for checking all sessions are properly executing, time keeping and collecting feedback from both students and tutors.</li>
              <p className='term_title'>Daily Report </p>
              <li>The daily progress report will be prepared by the tutor and submitted to the company and for you.</li>
              <li>The student will evaluate each report and will approve if all information on the report is correct, decline and comment if there is any information wrong, when notified to revise the correction check and approve only if he/she totally agreed.</li>
              <li>Once the meeting is approved it is not editable.</li>
              <p className='term_title'>schedule </p>
              <li>The tutoring session will be for two hours a day, and 2 days in a week .</li>
              <li>You can change your regular schedule any time, but you must acknowledge your tutor one week before, and you will discuss and decide on the new schedule.</li>
              <p className='term_title'>meeting time </p>
              
              <li>meeting time will be counted from the time which the tutor send the meetiing link upto the end of zoom meeting.</li>
              <li>The meeting link and the meeting is open only for the specified student, the tutor and for a person assigned for quality control.</li>
             
              <li>The tutor must open the meeting on time, if the student dosen't join the meeting the tutor will wait the student for minimum of 30 minutes while opened the meeting, the tutor can close the meeting only after 30 minutes.</li>

              <p className='term_title'>tutor responsibilities </p>
              <li>The tutor must set the meeting on time with regular schedule.</li>
              <li>The tutor must completely prepare on the topics covered on daily session</li>
              <li>The tutor must continiously analyse the student status and plan on things to consider for the next sessions and share his/her thoughts and opinions honestly and respectfully for the student about the strengths and weakness that he/she noticed. </li>
              <p className='term_title'>student responsibilities and rights</p>
              <li>The student must attend the regular session on regular time.</li>
              <li>The student must complete assignments given by the tutor.</li>
              <li>the student is expected to check each daliy reports 10 minutes after the session ended and give response on the report.</li>

            
              

              <p className='term_title'>Rescheduling (if the problem is on the tutor) </p>
              <li>The company will try as much as possible not to miss any regular schedule, when we notified the tutor is unable to attend the meeting we assign another tutor for that session</li>
              <li>The student has full right to decide whether to reschedule or contact another tutor.</li>
              <li>If the student meet with other tutor and if not satisfied on that session with his/her new tutor he/she has full right to repeat that session with his/her regular tutor.</li>

              <p className='term_title'>Rescheduling (if the problem is on the student) </p>
              <li>On this case there may or may not be rescheduling, it is on the right of the tutor.</li>
              <li>Rescheduling is only considered if the student notifies that he/she is unable to attend the meeting for his/her tutor 24 hours before meeting time.</li>
              </ul>            
              <p></p>
              
</div>
            <div className=''></div>
            <div className=''></div>
            <div className='new_trainee_wait__read py-3 px-5 d-flex justify-content-center'><button onClick={read}>Agree</button></div>
        </div>
        {/* <div className='py-3'>Once you agreed up on our terms of serviece submit your one month payment <strong>(200$) </strong>via <strong>Zelle</strong> or transfer to the bank to get payment details contact us on whatsapp 
        <strong>commonwealth one credit union # 3080868/Meskerem Mamo </strong>
         
        </div> */}
{/*  
 <div className='py-3'>send screenshoot of your successful transaction or payment recipt via <div className='d-flex align-items-center'><strong >whatsapp </strong><a className='contacts d-flex align-items-center fw-bold px-2' href='https://wa.me/message/6FPKHSIQ2QTYM1' target="_blank" >< FaWhatsapp className="text-success fs-2"/> +251946330505</a> or </div><div><strong>email:-</strong> andmtaskills.support.com</div> */}

 <div className='py-3'>Once you agreed up on our terms of serviece contact us via <div className='d-flex align-items-center'><strong >whatsapp </strong><a className='contacts d-flex align-items-center fw-bold px-2' href='https://wa.me/message/6FPKHSIQ2QTYM1' target="_blank" >< FaWhatsapp className="text-success fs-2"/> +251946330505</a> or +251921309013</div><div><strong>email:-</strong> andmtaskills.support.com</div>

        {/* <div className='py-3'>you will recieve a confirmation email with in 24 hours if you havent recieve confirmation email or any difficulty regarding payment contact <span className='contacts d-inline-block'><a className='d-flex align-items-center fw-bold px-2 ' href='https://t.me/contact_andmtaskills' target="_blank" >< FaTelegram className="fs-2"/></a></span><span className='contacts d-inline-block'><a className='d-flex align-items-center fw-bold px-2' href='https://wa.me/message/6FPKHSIQ2QTYM1' target="_blank" >< FaWhatsapp className="text-success fs-2"/> +251946330505</a></span></div> */}
        </div>
    </div>
    </div>
 
  )
}

export default NewTraineeWait
import React, { useEffect, useState } from 'react'
import './QandA.css'

function QandASingle({question,answer,link}) {
    const [questions,setquestions]=useState("")
  
    useEffect(() => {
        fetch(process.env.REACT_APP_GET_QandA)
          .then((response) => response.json())
          .then((data) => {
            
            if (data) {
              setquestions(data);
            } else {
              // console.log(err)
            }
          });
      }, []);
    
   
      const seemore = (e)=>{
        e.preventDefault()
        let answer=document.querySelector('.single__question__answer')
        let button=document.querySelector('.single__answer__see__more')
     
        e.target.parentElement.parentElement.parentElement.children[1].classList.toggle('show');
        e.target.parentElement.children[0].classList.toggle('d-none')
        e.target.parentElement.children[1].classList.toggle('d-none')
      }
  return (
    <div className='qandasingle container mb-4'>

    
    <div className='single__question '>
    <div className='single__question__question'>Question :-{question}</div>
    <div className='single__question__answer show ps-3 ps-md-5'><div>Answer :- </div> 
    {answer}</div>
    <div className='d-flex justify-content-between align-content-center'>
    <button className='single__answer__see__more ' onClick={(e)=>seemore(e)}><div className='more'>Read more ...</div><div className='Less d-none'>Read Less ...</div></button>
    {link?<a href={link} className="answer__link mb-2" target="_blank">Visit Source</a>:""}
    </div>
    </div>
 

    </div>
  )
}

export default QandASingle
// import './App.css';
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Group from "./components/GroupProjects/Group";
import Footer from "./components/Footer/Footer";
import Personal from "./components/PersonalTraining/Personal";
import Sign from "./components/Sign/Sign";
import HostingForm from "./components/GroupProjects/HostingForm/HostingForm";

import PersonalTrainingRequest from "./components/PersonalTraining/PersonalRequest/PersonalTrainingRequest";
import ConfirmedProjects from "./components/GroupProjects/Confirmed_projects/ConfirmedProjects";
import { useStateValue } from "./components/StateProvider/StateProvider";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import Admin from "./components/Admin/Admin";

// import UpcomingPro from "./components/Admin/ProjectData/UpcomingProjects/UpcomingPro";
import SignIn from "./components/Sign/SignIn/SignIn";
import User from "./components/User/User";
import Protected from "./Protected";
import TutorReport from "./components/Report/TutorReport";
import './App.css'
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Resources from "./components/Resources/Resources";
import Loader from "./components/Loader/Loader"

function App() {
  const [{ user,loading }, dispatch] = useStateValue();
  const [currentuser, setcurrentuser] = useState("");

  const [admin, setadmin] = useState("");
  // const[loading,setloading]= useState(false);
  // useEffect(()=>{
  //   setloading(true)
  //   setTimeout(() => {
  //     setloading(false)
  //   }, 3000);

  // },[])
  
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      // console.log("THE USER IS >>> ", authUser);
      if (authUser) {
        // the user just logged in / the user was logged in

        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        // the user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
  }, []);

  useEffect(()=>{
    dispatch({
      type: 'SET_LOADING',
      load: true,
    });
    setTimeout(() => {
      dispatch({
        type: 'SET_LOADING',
        load: false,
      });
    }, 3000);
  },[])

  // useEffect(() => {
  //   auth.onAuthStateChanged((authUser) => {
  //     // console.log("THE USER IS >>> ", authUser);
  //     if (authUser) {
  //       // the user just logged in / the user was logged in
  //       setcurrentuser(authUser.email);
  //       console.log(authUser.email);
  //       if (authUser.email === "totilahunfeyissa@gmail.com") {
  //         setadmin(true);
  //       } else {
  //         setadmin(false);
  //       }
  //     }
  //   });
  // }, []);

  // console.log(admin);
  
  return (
    <div className="app"> 
    kjhgkjhg jkghj
    {loading?<Loader/>:""}
    
      <BrowserRouter>
      <ScrollToTop/>
        <Header />
       
        
        <Routes >
          
          <Route path="/" element={<Home />}></Route>
          <Route path="/group" element={<Group />}></Route>
          <Route path="/personaltraining" element={<Personal />}></Route>
          <Route path="/signup" element={<Sign />}></Route>

          <Route path="/hostingform" element={<HostingForm />}></Route>
          <Route path="/resources" element={<Resources />}></Route>
         
          <Route
            path="/personaltrainingrequestform"
            element={<PersonalTrainingRequest />}
          ></Route>
          <Route
            path="/ConfirmedProjects"
            element={<ConfirmedProjects />}
          ></Route>
          <Route
            path="/admin"
            element={
              
                <Admin />
              
            }
          />
          <Route path="/user" element={<User />} ></Route>

          {/* <Route path="/UpcomingProjects" element={<UpcomingPro />}></Route> */}
          {/* <Route path="/test2" element={<Test2 />}></Route> */}
 
          <Route path="/tutorreport" element={<TutorReport />}></Route>

          {/* <Route path="/header" element={<Header/>}></Route> */}
          {/* <Route path="/" element={<Home/>}></Route> */}
          
        </Routes>
        
        <Footer className="app_footer "/>
      </BrowserRouter>
      
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import SingleTemplate from "./SingleTemplate";
import "./Templates.css";

function Templates() {
  const [templates, settemplates] = useState("");
  const [tosort, settosort] = useState([]);
  useEffect(() => {
    fetch(process.env.REACT_APP_GET_TEMPLATES)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
            data.sort().reverse()
          const sorter = [];
          for (let index = 0; index < data.length; index++) {
            sorter.push([data[index], data[index].order]);
          }
         

          const sorted = [];
          const sortedarray = sorter.sort();
          for (let index = 0; index < sortedarray.length; index++) {
            sorted.push(sortedarray[index][0]);
          }
         

          settemplates(sorted);
        } else {
        }
      });
  }, []);

  return (
    <div className="templates mt-2 p-2">
      {templates &&
        templates.map((data) => {
          return (
            <SingleTemplate
              image={data.image}
              title={data.title}
              description={data.description}
              price={
                Number(data.price) !== "NaN" && Number(data.price) !== 0
                  ? data.price + "$"
                  : "Free"
              }
              preview={data.preview}
              download={data.download}
            />
          );
        })}
    </div>
  );
}

export default Templates;

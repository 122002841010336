import React, { useEffect, useState } from "react";
import StudyHead from "../Study/StudyHead/StudyHead";
import "./Personal.css";
import zoom from "../../resources/images/Online-Tutoring.jpeg";
import { Link } from "react-router-dom";
import SignInFirstModal from "../Sign/SIgnInFirstModal/SignInFirstModal";
import { useStateValue } from "../StateProvider/StateProvider";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";

function Personal() {
  const [{basket,user}, dispatch]=useStateValue();
  const[users,setusers]=useState("")
  const[emaillist,setemaillist]=useState("")
  const[currentuser,setcurrentuser]=useState("");
  // user table query map from sql database
useEffect(() => {
  fetch(process.env.REACT_APP_USERSFETCH)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        setusers(data);
      } else {
        // console.log(err)
      }
    });
}, []);
 // user email query from firebase
 useEffect(()=>{
  onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setcurrentuser(email)
      } else {
          setcurrentuser("Sign IN")
      }
    });
  
})
  useEffect(()=>{
    let emails=[]
    users && users.map((userr)=>{
      
      // console.log(user.role)
      if(userr.role === "student" || userr.role === "trainer"){emails.push(userr.email)}
  
      
    })
    setemaillist(emails)
  },[users])
  return (
    <div className="personal container">
      
      <div className="personal__study">
        
        {!user?<SignInFirstModal
        button_text={<StudyHead />}/>:<>{
          (emaillist.includes(currentuser))? <Link to="/tutorreport"> <StudyHead /></Link>: <Link to="/personaltrainingrequestform"> <StudyHead /></Link>
        }</>}
        
      
        
      </div>
      {/* <div className="personal__banner">
        <div className="personal__banner__text">
          If you START to climb a mountain, you deserve to see the PEAK of it!!
        </div>
        <div className="personal__banner__button">
          <button>Apply Now</button>
        </div>
      </div>
      <div className="personal__body">
        <div className="personal__body__left"></div>
        <div className="personal__body__right"></div>
      </div>
      <div className="personal__note"></div>
      <div className="personal__apply"></div> */}
      <div className="personal__note">
        This program is mainly for those who completed or currently enrolling on
        Full stack web development program, and need an
        additional online personal assistant.<div> Note:- andmta skills  is a company of its own, it is not working in a partnership with any other company.</div>
      </div>
      <div className="personal__body__bottom__cont my-4 my-lg-2">
            <div className="personal__body__bottom__head">
              If you complete the course
            </div>
            <div className="personal__body__bottom__text">
              You will get a full revision or specific topics you select from
              the course and practice with examples, Full assistance on
              projects, error correction, methods explanation that used in a
              project, deploying a project,building your portfolio, and customizing yor linkedIn profile.
            </div>
          </div>
      <div className="personal__body">
        <div className="personal__body__left ">
          <div className="personal__body__left__head">
            If you are a student in full stack web development course & want to get this tutor,
          </div>
          <div className="personal__body__left__text">
            You must attend group meetings and live sessions , complete assignments, checklist, and other course requirements by yourself.
            <br />
            We only give assistance on 
            <ul>
              <li>highlighting the coming class topics
            for better understanding when you attend the class</li>
              <li>practice
            examples related to assignments and a portion covered on your regular class</li>
              <li>detailed
            explanations on points you don’t understand during class</li>
              <li>if you are behind in
            class for any reason, a quick revision on topics you failed to
            attend to get back you to the class</li>
              <li>if you are new to the
            technology field and computers, struggling to study alone and need
            more help andmta skills is there for you</li>
            </ul>

          </div>
        </div>
        <div className="personal__body__right">
          <div className="personal__body__right__image ">
            <img src={zoom}></img>
          </div>
          {!user?<SignInFirstModal className="apply w-100 "
        button_text={<button className="apply w-100 ">APPLY NOW</button>}/>:<>{
          (emaillist.includes(currentuser))? <Link to="/tutorreport" className="apply w-100 "> APPLY NOW</Link>: 
          <Link to="/personaltrainingrequestform"> 
          <button className="apply w-100 ">APPLY NOW</button>
          </Link>
        }</>}
          
        </div>
      </div>
      
      {/* <div className="personal__note">
      You will select your assistant, preferred schedule time, and methodology of the study. A trial period will be for <strong> 2 weeks </strong>, you can claim a refund if you want to quit the program. 
      </div> */}


     
    </div>
  );
}

export default Personal;

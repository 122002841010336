import React, { useEffect, useState } from 'react'
import './Project.css'

function Project({Project_title,st_date,fname,lname,day1,time1,day2,time2,button}) {

  const remainingdateCalc=()=>{
    const currentDate = new Date();
  const startingDate= new Date(st_date)
  const cdate=currentDate.getTime()
  const sdate=startingDate.getTime()
  const tzdate=startingDate.getTimezoneOffset()
  const dayInms=1000*3600*24
  const rem=((sdate-cdate)%dayInms)
  const result=(((sdate-cdate-rem)/dayInms))
  // const final=()=>{if(result>=0){return result}else{return "started"}}
  // console.log(tzdate)
  // console.log(startingDate)
  
  return result
  }
  const remainingdate=()=>{
    remainingdateCalc()
    if(remainingdateCalc() > 0 ){return remainingdateCalc()} else{return "-"}

  }
 

  
  return (
    <div className='project  container'>
      
        <div className='project__counter'><p><p>{remainingdate()}</p> days remaining <br/> </p></div>
        
        <div className='project__details'>
        <div className='project__detail'>
            <p>Title :-<p>{Project_title }</p></p><br/>
            <p>Host :-<p>{fname}{" "}{lname}</p></p><br/>
            <p>Starting Date :-<p>{st_date}</p></p><br/>
            <p>Schedule :-<p>{day1}-{time1} {day2?"&"+day2+'-':'' }{time2} EST for 2-hours</p></p><br/>
        </div>
        
        <div className='project__join'><button >{button}</button>
        </div>
        </div>
        <div></div>
    </div>
  )
}

export default Project
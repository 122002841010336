import React, { useEffect, useState } from "react";
import "./Home.css";
import Header from "../Header/Header";
import { Card } from "react-bootstrap";
import StudyHead from "../Study/StudyHead/StudyHead";
import bann from '../../resources/images/study_banner.jpg'
import bannee from '../../resources/images/Andmta_logo.png'
import { db } from "../../firebase";
import BeHost from "../GroupProjects/BeHost"
import { Link } from "react-router-dom";
import Videos from "./Videos/Videos";
function Home() {
  const[reqe,setreqe]=useState([])
  const[confirmed,setconfirmed]=useState([])
  useEffect(() => {
    db.collection("hosting_form")
      .orderBy("st_date","desc")   
      .onSnapshot((snapshot) =>
        setreqe(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  }, []);
  
  useEffect(()=>{
    reqe?.map((item)=>{
if (item.data.status==="confirmed"){
  setconfirmed(item.data)
}
    })
  })
 
  return (
    <div className="home ">
      
      <div className="home__banner ">
     
        <div className="home__banner__text">
          <p className="heading">
            A place where
            <p>DEVELOP YOUR SKILLS</p>
          </p>
        </div>
      </div>

      <div className="Home__group__parent ">
      <Link to="/ConfirmedProjects" className="d-flex justify-content-center"> 
      <div key={reqe.id} className="Home__group d-flex-md">
      
        <div className="Home__group__left d-flex ">
          <div className=" Home__group__left__text p-2">
            <p>UPCOMING GROUP PROJECT </p>
          </div>
        </div>
        <div className="Home__group__right">
          <div className="Home__group__right__item">
            <p className="d-flex w-100">
              <p className="text-end">Title:-</p>
              <p className="text-start ">{confirmed.Project_title}</p>
            </p>
          </div>
          <div className="Home__group__right__item ">
            <p className="d-flex">
              <p className="flex-start">Starting Date:-</p>
              <p>{confirmed.st_date}</p>
            </p>
          </div>
          <p className="Home__group__right__see">see more</p>
        </div>
        
      </div>
      </Link> 
      
      </div>     
      {/* <div className="Home__group"><Link to="/hostingform"><BeHost/></Link></div> */}
 
      

      <div className="home__study">
      <Link to='/personaltraining'><StudyHead /></Link>
      </div>



      <Videos/>
      



      
   
    </div>
  );
}

export default Home;

import React, { useEffect, useState } from 'react'
import "./Header.css"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar";
import Container from 'react-bootstrap/Container';
// import { NavLink as Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { onAuthStateChanged,signOut  } from "firebase/auth";
import {BsFillPersonFill} from "react-icons/bs/";
import {
	Menu,
	MenuList,
	MenuButton,
	MenuItem,
	MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";


import 'bootstrap/dist/css/bootstrap.min.css';
import { useStateValue } from '../StateProvider/StateProvider';
import { db,auth } from '../../firebase';
import Profile from './Profile';
import e from 'cors';

function Header() {
    const [{ basket, user }, dispatch] = useStateValue();
    const[currentuser,setcurrentuser]=useState("");
    const[userdata,setuserdata]=useState("")
    const[users,setusers]=useState("")
    const[fname,setfname]=useState("")
    const[lname,setlname]=useState("")
    const[emaillist,setemaillist]=useState("")

    // user email query from firebase
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              const uid = user.uid;
              const email = user.email;
              setcurrentuser(email)
            } else {
                setcurrentuser("Sign IN")
            }
          });
        
    })
    
    // userdata query from firebase
    useEffect(()=>{
        db.collection("users").onSnapshot((snapshot)=>
        setuserdata( snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        })
        ))
        )
      },[]);

    // user fname and lname map
    useEffect(()=>{
        {userdata &&
          userdata.map((userdata) => {
            if(currentuser===userdata.data.email){
              setfname(userdata.data.fname)
              setlname(userdata.data.lname)
            }
          })
      }})


const collapse = event => {
  // 👇️ toggle isActive state on click
  // setshow(current => !current);
  event.currentTarget.classList.remove('show');

};

// user table query map from sql database
useEffect(() => {
  fetch(process.env.REACT_APP_USERSFETCH)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        setusers(data);
      } else {
        // console.log(err)
      }
    });
}, []);
useEffect(()=>{
  let emails=[]
  users && users.map((userr)=>{
    
    // console.log(user.role)
    if(userr.role === "student" || userr.role === "trainer"){emails.push(userr.email)}

    
  })
  setemaillist(emails)
},[users])
   



    return (
 <div className='header fixed-top '>
    <div className='header__container  '>
    
    <div className=' header__left  d-flex '>
        <p className=' header__left__a' >ANDMTA</p> 
        <p className=' header__left__s'>Skills</p>

        </div>
        
        <div className='header__right'>
            <Navbar collapseOnSelect expand="md">
            {/* <Container> */}
            
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav" onClick={collapse}>
            <Nav className=' header__right__navs ' >
                <Link to="/">HOME</Link>
                <Link to="/Group"> PROJECTS</Link>
                <Link to="/resources"> RESOURCES</Link>
                {/* <Nav.Link><Link to="/personaltraining"> TUTOR</Link></Nav.Link> */}
                {/* {users && users.map((user)=>{
                  if(user.email.includes(currentuser)){return <Link to="/tutorreport"> TUTOR</Link>}else{return <Link to="/personaltraining"> TUTORs</Link>}
                })} */}
                {
                  (emaillist.includes(currentuser))? <Link to="/tutorreport"> TUTOR</Link>: <Link to="/personaltraining"> TUTOR</Link>
                }
                

                {/* {!user? <Nav.Link><Link className="ttt" to="/signup">{!user? "SiGN IN":""}</Link></Nav.Link>:""} */}
              
                {/* <Nav.Link><Link className="ttt" to="/signup">{!user? "SiGN IN":""}</Link></Nav.Link> */}
                
                {/* <Nav.Link><Link className="ttt" to="/">
                    <div><div>{fname}{lname[0]}</div><div>
                {!user? "":<button onClick={signout}>SIGN OUT</button>}</div></div>
                </Link></Nav.Link> */}
                
                {!user ? <Link to="/Signup" className='profile'><Profile/></Link>: <Link to="/user" className='profile'><Profile/></Link>}
                  
                
                
                
                
                
            </Nav>
            </Navbar.Collapse>
            {/* </Container> */}
            </Navbar>
            
            
  </div>

 
  </div>
  
    
  </div>


      );
}

export default Header
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import "./MeetingReport.css";

function MeetingReport({
  email,
  start_time,
  Meeting_Link,
  today_plan,
  meetingdate,
  end_time,
  lessons_covered,
  next_day_plan,
  tutor_comment,
  comment,
  report_status,
  student_name,
}) {
  const [ed_time, seted_time] = useState("");
  const [emptylink, setemptylink] = useState("");

  const tConvert = (start_time) => {
    const tonum = Number(start_time);
    const date = new Date(tonum);
    var time = date.getHours() + ":" + date.getMinutes();
    time = time
      .toString()
      .match(/^(?:(?:([01]?\d|2[0-3])(:))?([0-5]?\d))$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours

      time[0] = +time[0] < 10 ? `0${+time[0]}` : time[0];
      time[2] = +time[2] < 10 ? `0${+time[2]}` : time[2];
    }
    return time.join(""); // return adjusted time or original string
  };

  const submitter=(e)=>{
    
    if(report_status==="pending" || report_status==="declined" || report_status===""){
    let updateform = document.querySelector(".updateform");
    updateform.action = process.env.REACT_APP_UPDATE_REPORT;
    updateform.method = "post";
    updateform.submit()}else
    {
      alert("Sory you can't edit approved meeting your update report failed")
    }
  }

  return (
    <div className="meeting_report tutor_daily_report">
      <form
        className="updateform"
        action={process.env.REACT_APP_UPDATE_REPORT}
        method="post"
      >
        <Table className="container rounded-3 ">
          <thead>
            <tr>
              <th>{student_name}</th>
              <th className="d-flex flex-column top flex-lg-row" colSpan="5">
                <span className="px-2">
                  {meetingdate}
                  <input
                    readOnly
                    className="d-none "
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                  ></input>
                  <input
                    readOnly
                    className="d-none "
                    type="text"
                    name="meetingdate"
                    id="meetingdate"
                    value={meetingdate}
                  ></input>
                </span>
                <span className="d-flex">
                  <p className="px-2">Starting Time :-</p>
                  {tConvert(start_time)}{" "}
                  <input
                    className=" d-none w-25 text-center "
                    name="start_time"
                    id="start_time"
                    defaultValue={start_time}
                  ></input>
                </span>
                <span className="d-flex ">
                  <p className="px-2">
                    End time :- {end_time ? tConvert(end_time) : ""}
                  </p>
                  <input
                    className="d-none w-25 text-center"
                    name="end_time"
                    id="end_time"
                    defaultValue={end_time ? end_time : ed_time}
                  ></input>
                </span>
                <span className="statusdiv text-uppercase text-dark px-2 text-left">
                  {report_status ? report_status : "pending"}
                </span>
                <input
                    className=" w-25 text-center d-none"
                    name="report_status"
                    id="report_status"
                    defaultValue={report_status ? report_status : "pending"}
                  ></input>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="d-flex flex-column d-sm-block">
              <td>meeting Link</td>
              <td colSpan="2">
                <input
                  className=""
                  defaultValue={Meeting_Link}
                  type="text"
                  id="Meeting_Link"
                  name="Meeting_Link"
                ></input>
                {/* <a href={Meeting_Link} target="_blank"> Join meeting</a> */}
              </td>
              <td>Lessons Covered</td>
              <td colSpan="2">
                <textarea
                  id="lessons_covered"
                  name="lessons_covered"
                  defaultValue={lessons_covered}
                ></textarea>
              </td>
            </tr>

            <tr className="d-flex flex-column d-sm-block">
              <td>Today Plan</td>
              <td colSpan="">
                <textarea
                  id="today_plan"
                  name="today_plan"
                  defaultValue={today_plan}
                  type="text"
                ></textarea>
              </td>

              <td>Tutor Comment</td>
              <td colSpan="2">
                <textarea
                  id="tutor_comment"
                  name="tutor_comment"
                  defaultValue={tutor_comment}
                  type="text"
                ></textarea>
              </td>
            </tr>

            <tr className="d-flex flex-column d-sm-block">
              <td>Next Day Plan</td>

              <td colSpan="2">
                <textarea
                  id="next_day_plan"
                  name="next_day_plan"
                  defaultValue={next_day_plan}
                ></textarea>
              </td>

              <td>Student Comment</td>
              <td colSpan="2">
                {comment}
                <textarea
                  className="d-none"
                  id="comment"
                  name="comment"
                  defaultValue={comment}
                ></textarea>
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="btn d-flex w-100" >
                {end_time?"":
                <button className="d-flex w-100 justify-content-center"
                  onClick={(e) => {
                    seted_time(new Date().getTime());
                  }}
                >
                  Stop Timer
                </button>}
              </td>
              <td></td>
              <td className="btn w-100" colSpan="3">
                {report_status==="approved"?"":
                <button className="d-flex w-100 justify-content-center" type="submit" value="submit">
                  Submit
                </button>}
              </td>
            </tr>
          </tbody>
        </Table>
      </form>
    </div>
  );
}

export default MeetingReport;

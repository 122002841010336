import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import './SignInFirstModal.css'

function SignInFirstModal({content,button_text}) {
    
    const [isShow, invokeModal] = useState(false)
    const initModal = () => {
        
      return invokeModal(!false)
    }
    const closeModal=()=>{
      // window.location.reload ()
      return invokeModal(false)
    }
    
    return (
      <div className='text-center'>
        <div className=" text-white" >
        <Button variant="" className=" text-white"onClick={initModal}>
          {button_text}
        </Button>
        </div>
        <Modal show={isShow}>
          {/* <Modal.Header closeButton onClick={initModal}>
            <Modal.Title>React Modal Popover Example</Modal.Title>
          </Modal.Header> */}
          <Modal.Body className="modalbox-body">
           
            <div className='modal-content-centered'>
                <div  className='modal-content-text'>please sign in first</div>
                




<div className='modal-buttons'>

            {/* <Button variant="danger" onClick={closeModal}>
              Close
            </Button> */}
            <Link to="/signup" className='modal-btn'>Sign In</Link>
            <button  onClick={closeModal} className='modal-btn'>
              cancel
            </button>
            </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="danger" onClick={closeModal}>
              Close
            </Button>
            
          </Modal.Footer> */}
        </Modal>
      </div>
      
    )
  }

export default SignInFirstModal